import React from 'react';
import {
  IconChevronDown,
  IconChevronUp,
  IconSelector,
  TablerIcon,
} from '@tabler/icons-react';
import { Center, Group, Table, Text, UnstyledButton } from '@mantine/core';
import classNames from 'classnames';

import classes from './SortableHeader.module.css';

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

export default function SortableHeader({
  children,
  reversed,
  sorted,
  onSort,
}: ThProps) {
  const Icon: TablerIcon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classNames(classes.control, sorted && classes.sorted)}
      >
        <Group justify='space-between' w='max-content'>
          <Text fw={700} fz='sm'>
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={16} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}
