import React, { useState, useEffect } from 'react';
import { Modal, Button, Divider, TextInput, CloseButton } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

type IndeedJob = {
  jobCity: string;
  jobState: string;
  jobTitle: string;
};

const EditCampaignModal = ({
  modalOpen,
  setModalOpen,
  initialJobs,
  updateCampaignJobs,
  saving,
}) => {
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');
  const callNewApplicants = true; // not sure if we want this editable
  const [editStateDone, setEditStateDone] = useState(false);

  const [editedJobs, setEditedJobs] = useState<IndeedJob[]>([]);

  useEffect(() => {
    if (initialJobs) {
      setEditedJobs(
        initialJobs.map((j) => ({
          jobCity: j.city,
          jobState: j.state,
          jobTitle: j.title,
        }))
      );
    }
  }, [initialJobs, setEditedJobs]);

  useEffect(() => {
    const hasEmptyProperty = editedJobs.some((job) =>
      Object.values(job).some((value) => value === '')
    );
    setEditStateDone(!hasEmptyProperty);
  }, [editedJobs]);

  const updateCityAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobCity: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const updateStateAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobState: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const updateTitleAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobTitle: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const addJob = () => {
    const newObj = [
      {
        jobCity: '',
        jobState: '',
        jobTitle: '',
      },
    ];
    setEditedJobs([...editedJobs, ...newObj]);
  };

  const handleRemove = (index) => {
    const newArray = [...editedJobs];
    newArray.splice(index, 1);
    setEditedJobs([...newArray]);
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      size='md'
      style={{ height: '80vh' }}
      title='Configure Indeed Application Calling'
    >
      {isInternal && (
        <>
          {editedJobs.map((job, idx) => (
            <div style={{ display: 'flex' }}>
              <div>
                <TextInput
                  style={{ marginBottom: '10px' }}
                  label={
                    idx === 0
                      ? 'Indeed Job Title, City, State (must match exactly)'
                      : `Indeed Job ${idx + 1}`
                  }
                  value={job.jobTitle}
                  placeholder='CNC Machinist'
                  onChange={(e) => updateTitleAtIndex(idx, e.target.value)}
                  disabled={!callNewApplicants}
                  required
                />
                <div style={{ display: 'flex' }}>
                  <TextInput
                    style={{
                      marginBottom: '10px',
                      width: '80%',
                      marginRight: '4px',
                    }}
                    label=''
                    placeholder='Greenville'
                    value={job.jobCity}
                    onChange={(e) => updateCityAtIndex(idx, e.target.value)}
                    disabled={!callNewApplicants}
                    required
                  />
                  <TextInput
                    style={{
                      marginBottom: '10px',
                      width: '20%',
                    }}
                    label=''
                    placeholder='SC'
                    value={job.jobState}
                    onChange={(e) => updateStateAtIndex(idx, e.target.value)}
                    disabled={!callNewApplicants}
                    required
                  />
                </div>
                <Divider my='md' />
              </div>
              <div style={{ alignContent: 'center', paddingLeft: '15px' }}>
                <CloseButton onClick={() => handleRemove(idx)} />
              </div>
            </div>
          ))}
          <div>
            <Button
              onClick={() => addJob()}
              leftSection={<IconPlus size={20} />}
              w='max-content'
            >
              Add Job
            </Button>
          </div>

          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button
              onClick={() => {
                updateCampaignJobs(editedJobs);
              }}
              disabled={saving || !editStateDone}
              style={{ opacity: saving || !editStateDone ? 0.5 : 1.0 }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditCampaignModal;
