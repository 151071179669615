import React from 'react';
import '@mantine/core/styles.css';
import './EditorPage.css';

// Note: this page is the reason, for now that we have the gray background

const EditorPage = () => {
  return <></>;
};

export default EditorPage;
