import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Title,
  ActionIcon,
  Menu,
  rem,
  Center,
  Loader,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import env from 'env';
import {
  IconDotsVertical,
  IconTrash,
  IconEdit,
  IconCopy,
} from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../api/axiosConfig';

const ScriptsPage = () => {
  const navigate = useNavigate();

  const [scripts, setScripts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchScripts = async () => {
    setScripts([]);
    setLoading(true);
    try {
      const response = await axios.get(
        `/saved_scripts/org/${localStorage.getItem('orgId')}`
      );
      const scriptsResp = response.data;

      setScripts(Object.values(scriptsResp));
    } catch (error) {
      console.error('Failed to fetch scripts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScripts();
  }, []);

  const navigateToScriptEditor = (scriptId) => {
    navigate(`/scripts/script-editor/${scriptId}`);
  };

  const duplicateScript = async (scriptId) => {
    const toDuplicate = scripts.find(
      (script) => script['script_id'] === scriptId
    );
    try {
      const scriptInfoCopy = { ...toDuplicate.script_info };
      scriptInfoCopy['name'] += ' (Copy)';
      scriptInfoCopy['scriptId'] = '' + uuidv4();

      await axios.post(
        `${env.REACT_APP_SERVER_URL}/saved_scripts`,
        scriptInfoCopy
      );

      fetchScripts();
    } catch (error) {
      console.error('Failed to duplicate script:', error);
    }
  };

  const deleteScript = async (scriptId) => {
    try {
      setScripts((prevScripts) =>
        prevScripts.filter((script) => script['script_id'] !== scriptId)
      );
      const response = await axios.delete(`/delete_script/${scriptId}`);
      const scriptsResp = response.data;

      setScripts(Object.values(scriptsResp));
    } catch (error) {
      console.error('Failed to fetch scripts:', error);
    }
  };

  const [selectedScriptIdx, setSelectedScriptIdx] = useState(-1);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  // TODO: make the headers sticky. Follow this https://ui.mantine.dev/category/tables/
  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} style={{ marginBottom: '20px' }}>
          Scripts
        </Title>
        <Button onClick={() => navigateToScriptEditor('')}>New Script</Button>
      </div>

      <div
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'scroll',
          background: 'white',
          borderRadius: '4px',
          border: '1px solid #8080805c',
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Owner</Table.Th>
              <Table.Th>Last Update</Table.Th> <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {scripts.map((script, index) => (
              <Table.Tr
                key={index}
                style={{
                  cursor: 'pointer',
                  background: selectedScriptIdx === index ? '#DCDCDC' : '',
                }}
                onClick={() => navigateToScriptEditor(script['script_id'])}
              >
                <Table.Td>{script['script_info']['name']}</Table.Td>
                <Table.Td>{script['author']}</Table.Td>
                <Table.Td>
                  {script['lastUpdateTs']
                    ? formatTimestamp(script['lastUpdateTs'])
                    : ''}
                </Table.Td>
                <Table.Td
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Menu
                    shadow='md'
                    width={200}
                    onChange={(e) => {
                      setSelectedScriptIdx(e ? index : -1);
                    }}
                  >
                    <Menu.Target>
                      <ActionIcon variant='subtle'>
                        <IconDotsVertical
                          size={20}
                          onClick={(e) => {
                            // e.stopPropagation();
                            // e.preventDefault();
                          }}
                        />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>{script['script_info']['name']}</Menu.Label>
                      <Menu.Item
                        leftSection={
                          <IconEdit
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          navigateToScriptEditor(script['script_id'])
                        }
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        leftSection={
                          <IconCopy
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() => duplicateScript(script['script_id'])}
                      >
                        Duplicate
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        color='red'
                        leftSection={
                          <IconTrash
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() => deleteScript(script['script_id'])}
                      >
                        Delete
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            ))}
            {loading && (
              <tr>
                <td colSpan={7}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

export default ScriptsPage;
