import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { fileTypeFromBuffer } from 'file-type';
import { Loader } from '@mantine/core';

const FilePreview = ({ fileUrl, backupText }) => {
  const [loadState, setLoadState] = useState('');
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    console.log('fetching new');
    setLoadState('');
    setFileType('');
    const fetchAndConvertFile = async () => {
      if (fileUrl) {
        console.log('fetching', fileUrl);
        try {
          const response = await fetch(fileUrl);
          const arrayBuffer = await response.arrayBuffer();
          const fileType = await fileTypeFromBuffer(arrayBuffer);
          console.log('got preview file type', fileType);
          if (fileType) {
            setFileType(fileType.ext);
          } else {
            setFileType('');
          }
          setLoadState('success');
          console.log('re-set file type and load state');
        } catch (error) {
          console.error('Error fetching or converting file:', error);
          setLoadState('failed');
        }
      }
    };

    fetchAndConvertFile();
  }, [fileUrl]);

  if (loadState !== 'failed' && fileType === 'docx') {
    return (
      <div>
        <FileViewer
          fileType={'docx'}
          filePath={fileUrl}
          errorComponent={<div>Error loading file</div>}
          unsupportedComponent={<div>Unsupported file type</div>}
        />
      </div>
    );
  }

  return (
    <div>
      {loadState ? (
        <div
          style={{
            height: '100%',
            backgroundColor: 'white',
            overflowY: 'scroll',
          }}
        >
          <pre
            style={{
              padding: '10px',
              fontSize: '12px',
              lineHeight: '18px',
              wordWrap: 'break-word' /* Break long words */,
              wordBreak: 'break-all' /* Break all lines */,
              whiteSpace: 'pre-wrap' /* Allow text to wrap */,
              fontFamily: 'Arial, sans-serif',
            }}
          >
            {backupText}
          </pre>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader size={20} />
        </div>
      )}
    </div>
  );
};

export default FilePreview;
