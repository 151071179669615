import React, { useState, useRef, useMemo } from 'react';
import {
  Button,
  Loader,
  FileInput,
  Textarea,
  Modal,
  SegmentedControl,
  Flex,
} from '@mantine/core';
import env from 'env';

import axios from '../../api/axiosConfig';
import '../sourcing/SourcingPage.css';
import ResumeChatInput from './components/ResumeChatInput';
import classes from './ResumeEditorPage.module.css';
import { removeHighlighting } from './components/utils';
import { ChatInputPanel } from './components/types';
import ResumeQuillEditor from './components/ResumeQuillEditor';

import 'react-chat-elements/dist/main.css'; // Make sure to import the styles
const Diff = require('diff');

type WorkExperience = {
  company_name: string;
  role: string;
  time_period: string;
  details: string[];
};

type ResumeObj = {
  experiences: WorkExperience[];
  skills: string[];
  full_name: string;
  certifications: string[];
  education: string[];
  summary_text: string[];
};

const EMPTY_RESUME_OBJ: ResumeObj = {
  experiences: [],
  skills: [],
  full_name: '',
  certifications: [],
  education: [],
  summary_text: [],
};

type GptMessage = {
  role: string;
  content: string;
};

const ResumeEditorPage = () => {
  const [uploadedResume, setUploadedResume] = useState(null);
  const [fileUploadedOnce, setFileUploadedOnce] = useState(false);
  const [resumeText, setResumeText] = useState('');
  const [resumeObj, setResumeObj] = useState<ResumeObj>(EMPTY_RESUME_OBJ);
  const [gptMessages, setGptMessages] = useState<GptMessage[]>([]);
  const [usePartial, setUsePartial] = useState<boolean>(false);
  const [chatPanel, setChatPanel] = useState<ChatInputPanel>(
    ChatInputPanel.Chat
  );
  const [jobDescription, setJobDescription] = useState('');

  const [extractingText, setExtractingText] = useState(false);
  const [updatingResume, setUpdatingResume] = useState(false);

  const everythingDisabled = extractingText || updatingResume;

  const [editorContent, setEditorContent] = useState('');
  const [originalEditorContent, setOriginalEditorContent] = useState('');
  const [jobSkills, setJobSkills] = useState<string[]>([]);
  const [potentialJobSkills, setPotentialJobSkills] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string>();

  const editorContentNoHighlighting = useMemo(() => {
    return removeHighlighting(editorContent);
  }, [editorContent]);

  const userModified = editorContentNoHighlighting !== originalEditorContent;

  const resumeExists =
    resumeObj.full_name !== '' ||
    editorContent.replace(/<[^>]*>/g, '').trim() !== '';

  const extractResumeText = async (resumeBase64: string) => {
    setResumeObj(EMPTY_RESUME_OBJ);
    setGptMessages([]);
    setExtractingText(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_document_text`,
        {
          document: resumeBase64,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { text } = response.data;
      setResumeText(text);
      extractResumeFields(text, true);
      setExtractingText(false);
    } catch (error) {
      console.error('Error extracting resume text:', error);
      alert('error extracting resume text');
      setExtractingText(false);
    }
  };

  const extractResumeFields = async (
    resumeText: string,
    allowEdits: boolean
  ) => {
    setUpdatingResume(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_resume_json`,
        {
          resumeText: resumeText,
          allowEdits: allowEdits,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { resumeJson } = response.data;
      setResumeObj(resumeJson);
      const serverMsg: GptMessage = {
        role: 'assistant',
        content: JSON.stringify(resumeJson),
      };
      if (allowEdits) {
        // allowEdits === False implies we are downloading, not adjusting the messages.
        // allowEdits === true implies that we requested edits
        setGptMessages([serverMsg]);
      }
      setUpdatingResume(false);
      return resumeJson;
    } catch (error) {
      console.error('Error extracting resume text:', error);
      setUpdatingResume(false);
      return null;
    }
  };

  const sendNewMessage = async (msg: string, metadata: any = undefined) => {
    setUpdatingResume(true);
    const newMsg: GptMessage = {
      role: 'user',
      content: msg,
    };
    const isPartial = gptMessages.length === 0 && usePartial;
    let updatedGptMessages;
    if (userModified) {
      const diffs = Diff.diffWords(
        originalEditorContent,
        editorContentNoHighlighting
      );
      let diffMsg = '';
      let nextBold = false;
      let boldAdded = false;
      diffs.forEach((d) => {
        if (d.value.replace(/[<>]/g, '').trim() === '') {
          return;
        }
        // handle bold add / remove
        if (d.value.includes('<strong>')) {
          nextBold = true;
          boldAdded = !!d.added;
          return;
        } else if (d.value.includes('</strong>')) {
          nextBold = false;
          return;
        }
        if (nextBold) {
          if (boldAdded) {
            diffMsg += `MANUALLY MADE BOLD: '${d.value}', `;
          } else {
            diffMsg += `MANUALLY REMOVED BOLD: '${d.value}', `;
          }
          return;
        }

        if (d.removed) {
          diffMsg += `MANUALLY REMOVED: '${d.value}', `;
        } else if (d.added) {
          diffMsg += `MANUALLY ADDED: '${d.value}', `;
        }
      });

      if (diffMsg !== '') {
        diffMsg = 'DIFFS: ' + diffMsg;
      }

      const newManualEditMessage: GptMessage = {
        role: 'user',
        content:
          'The user made some manual edits to the content. Later in this message is a string with the full resume after the user modified, as well as a list of all the diffs. You should use the resume content below as the current edit state:' +
          diffMsg +
          '\nNEW RESUME:' +
          editorContentNoHighlighting,
      };
      // newMsg.content +=
      //   ' Also do not adjust the changes made in the last system message';
      if (msg) {
        updatedGptMessages = [...gptMessages, newManualEditMessage, newMsg];
      } else {
        updatedGptMessages = [...gptMessages, newManualEditMessage];
      }

      setGptMessages(updatedGptMessages);
    } else {
      updatedGptMessages = [...gptMessages, newMsg];
      setGptMessages(updatedGptMessages);
    }

    // This is a race condition if the user sends a second message, but we block that so it should be okay
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/update_resume_fields`,
        {
          messageHistory: updatedGptMessages,
          fromScratch: !resumeExists,
          partial: isPartial,
          jobMetadata: metadata,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { resumeJson, jobMetadata } = response.data;
      const serverMsg: GptMessage = {
        role: 'assistant',
        content: JSON.stringify(resumeJson),
      };
      const updatedGptMessagesWithResponse = [...updatedGptMessages, serverMsg];
      setGptMessages(updatedGptMessagesWithResponse);
      if (resumeJson && Object.entries(resumeJson)?.length !== 0) {
        setResumeObj(resumeJson);
      }
      if (jobMetadata) {
        if ('skills' in jobMetadata) {
          setJobSkills(jobMetadata['skills']);
        }
        if ('includedSkills' in jobMetadata) {
          setPotentialJobSkills(jobMetadata['includedSkills']);
        }
        if ('companies' in jobMetadata) {
          setCompanies(jobMetadata['companies']);
        }
      }
      setUpdatingResume(false);
      return resumeJson;
    } catch (error) {
      console.error('Error extracting resume text:', error);
      setUpdatingResume(false);
    }
  };

  const handleFileSelect = (file) => {
    if (file) {
      setUploadedResume(file);
      setFileUploadedOnce(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64String = String(e.target?.result || '').split(',')[1]; // Remove the data URL part

        // Send the base64 string to the backend
        extractResumeText(base64String);
      };

      reader.readAsDataURL(file); // Read the file as Data URL
    }
  };

  // const renderPdf = useMemo(() => (c: Candidate) => {
  //     return <PDFViewer fileUrl={c.resumeUrl} backupText={c.resumeText} />
  // }, []);

  const fileInputRef = useRef<any | null>(null);

  const handleDownloadResponse = (response) => {
    const blob = response.data;
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${resumeObj.full_name}_Resume.docx`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  };

  const DownloadResumeButton = () => {
    const handleDownload = async () => {
      let new_obj = resumeObj;
      if (userModified) {
        // new_obj = await extractResumeFields(editorContent, false);
        new_obj = await sendNewMessage('');
      }
      const data = {
        // Include your payload here
        name: new_obj.full_name,
        summary_text: new_obj.summary_text,
        education_entries: new_obj.education,
        skills: new_obj.skills,
        experiences: new_obj.experiences,
        qualifications: new_obj.certifications,
      };

      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/download_resume_docx`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            responseType: 'blob',
          }
        );

        handleDownloadResponse(response);
      } catch (error) {
        console.error('Failed to download the file:', error);
      }
    };

    return (
      <Button onClick={handleDownload} disabled={everythingDisabled}>
        Download Docx
      </Button>
    );
  };

  const ReformatTextButton = () => {
    const handleReformat = async () => {
      await extractResumeFields(editorContentNoHighlighting, true);
    };

    return (
      <Button
        onClick={handleReformat}
        disabled={!editorContent || everythingDisabled}
      >
        Format Resume
      </Button>
    );
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e) => {
    preventDefaults(e);

    const files = e.dataTransfer.files;
    if (files.length) {
      fileInputRef.current.files = files;
      handleFileSelect(files[0]);
    }
  };

  // TODO: this seems to behave very badly in a lot of cases
  const extractWordsFromHTML = (html: string): string[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const words: string[] = [];

    const extractText = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        words.push(node.textContent?.trim() || '');
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(extractText);
      }
    };

    extractText(doc.body);

    return words.filter((word) => word.length > 0);
  };

  const SKIP_WORDS = ['of', 'in', 'to', 'and', 'with', 'the', 'using'];

  const resumeEditorStrList = extractWordsFromHTML(editorContent);

  const resumeEditorStrListLowered = resumeEditorStrList
    .join(' ')
    .replace(/[.,!?]/g, '')
    .replace(/[-()]/g, ' ')
    .split(' ')
    .filter((s) => s)
    .filter((s) => !SKIP_WORDS.includes(s))
    .map((s) => s.toLocaleLowerCase());

  const jobSkillsStrList =
    jobSkills
      .join(' ')
      .replace(/[.,!?]/g, '')
      .replace(/[-]/g, ' ')
      .split(' ') || [].filter((s) => s).filter((s) => !SKIP_WORDS.includes(s));

  const jobSkillsStrListLowered = jobSkillsStrList
    .map((s) => s.toLocaleLowerCase())
    .filter((s) => !SKIP_WORDS.includes(s)); // TODO: memoize (also memoize in resume ediotr)

  const highlightedSkills = jobSkills.map((skill) => {
    return (
      <div>
        {skill
          ?.replace(/[^a-zA-Z0-9]/g, ' ')
          .split(/\s+/)
          .map((word, index) => {
            const cleanWord = word.replace(/[.,!?"]/g, '').toLocaleLowerCase(); // Remove punctuation for matching
            if (resumeEditorStrListLowered.includes(cleanWord)) {
              return (
                <span
                  key={index}
                  style={{
                    backgroundColor: 'rgb(0 251 255 / 46%)',
                    fontWeight: 'bold',
                  }}
                >
                  {word}{' '}
                </span>
              );
            }
            return <span key={index}>{word} </span>;
          })}
      </div>
    );
  });

  const highlightedJobDescription = (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {jobDescription?.split(/\n/).map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line.split(' ').map((word, wordIndex) => {
            const cleanWord = word.replace(/[.,!?"]/g, '').toLocaleLowerCase(); // Remove punctuation for matching
            if (
              jobSkillsStrListLowered.includes(cleanWord) &&
              resumeEditorStrListLowered.includes(cleanWord)
            ) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    backgroundColor: 'rgb(0 251 255 / 46%)',
                    fontWeight: 'bold',
                  }}
                >
                  {word}{' '}
                </span>
              );
            }
            return <span key={wordIndex}>{word} </span>;
          })}
          <br /> {/* Add a line break after each line */}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div style={{ padding: '20px' }}>
      {/* <div className="top-row">
                <Title order={2} style={{ marginBottom: '20px' }}>Sourcing</Title>
                <div className="center-buttons">
                    <Button size="sm" className="top-button job-button" onClick={() => setJobDescriptionModalOpen(true)}>
                            Edit Job Description
                    </Button>
                    {renderDownloadButton()}
                </div>

                <div style={{marginLeft: "auto", width: "200px"}}>
                    {renderMaxCandidates()}
                </div>
            </div> */}

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '60%' }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <p style={{ marginTop: '14px', marginRight: '20px' }}>
              {resumeExists
                ? 'Upload a new resume to start over'
                : 'Upload an existing resume for formatting'}
            </p>
            <div
              style={{ width: '190px', alignContent: 'center' }}
              onDragOver={preventDefaults}
              onDrop={handleFileDrop}
            >
              <FileInput
                placeholder='Upload Resume File'
                ref={fileInputRef}
                error={fileUploadedOnce && !uploadedResume}
                onChange={handleFileSelect}
                disabled={everythingDisabled}
              />
            </div>
            {extractingText ? (
              <div
                style={{
                  display: 'flex',
                  paddingLeft: '18px',
                  alignItems: 'center',
                }}
              >
                <p style={{ margin: '0px' }}>Extracting Text</p>
                <div
                  style={{
                    alignContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Loader size={20} style={{ paddingTop: '3px' }} />
                </div>
              </div>
            ) : (
              <div
                style={{
                  paddingLeft: '18px',
                  color: 'gray',
                }}
              >
                {resumeText && 'Resume text extracted'}
              </div>
            )}
          </div>
          <div
            className={`${classes.hideable} ${gptMessages.length > 0 ? classes.hide : ''}`}
          >
            {/*style={{ display: resumeExists ? 'none' : '' }}>*/}
            <h3 style={{ marginTop: '16px' }}>OR</h3>
            <p>
              Begin creating a resume from scratch in the chat by providing job
              details.
            </p>
          </div>

          <Modal opened={false} onClose={() => {}}>
            <div
              style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'scroll',
              }}
            >
              <Textarea
                style={{
                  marginBottom: '10px',
                  paddingRight: '10px',
                }}
                value={resumeText}
                minRows={1}
                placeholder=''
                autosize
                // onChange={(e) => handleChange(index, e.target.value)}
              />
            </div>
          </Modal>
          <div
            style={{
              marginTop: '2vh',
              opacity: everythingDisabled ? 0.4 : 1.0,
              pointerEvents: everythingDisabled ? 'none' : 'auto',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {resumeExists && (
                <h4 style={{ marginBottom: '2px' }}>Request Adjustments</h4>
              )}
              {resumeExists && jobSkills?.length > 0 && (
                <SegmentedControl
                  value={chatPanel}
                  onChange={(v) => setChatPanel(v as ChatInputPanel)}
                  data={[ChatInputPanel.Chat, ChatInputPanel.JobDetails]}
                />
              )}
            </div>
            {chatPanel === ChatInputPanel.Chat || !chatPanel ? (
              <ResumeChatInput
                sendNewMessage={sendNewMessage}
                disabled={everythingDisabled}
                gptMessages={gptMessages}
                jobDescription={jobDescription}
                setJobDescription={setJobDescription}
                potentialJobSkills={potentialJobSkills}
                setPotentialJobSkills={setPotentialJobSkills}
                jobSkills={jobSkills}
                companies={companies}
                resumeExists={resumeExists}
                usePartial={usePartial}
                setUsePartial={setUsePartial}
              />
            ) : (
              <Flex style={{ height: '70vh' }}>
                {' '}
                {/* Set a fixed height or use a dynamic one */}
                <div
                  style={{
                    fontSize: '12px',
                    padding: '16px',
                    background: 'white',
                    borderRadius: '12px',
                    overflowY: 'auto', // Makes the content scrollable
                    flex: 2,
                    height: '100%', // Ensures it takes up the full height
                  }}
                >
                  <h3>Job Description</h3>
                  {highlightedJobDescription}
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    padding: '16px',
                    background: 'white',
                    borderRadius: '12px',
                    overflowY: 'auto', // Makes the content scrollable
                    flex: 1,
                    height: '100%', // Ensures it takes up the full height
                    marginLeft: '16px',
                  }}
                >
                  <h3>Extracted Skills</h3>
                  {highlightedSkills}
                </div>
              </Flex>
            )}
          </div>
        </div>

        <div style={{ width: '3.5%' }}>{/* padding */}</div>
        <div style={{ width: '38%' }}>
          <div style={{ display: 'flex' }}>
            <h3 style={{ marginBottom: '6px' }}>Resume Content Preview</h3>
            {updatingResume && (
              <div
                style={{
                  alignContent: 'center',
                  marginLeft: '20px',
                  paddingTop: '11px',
                }}
              >
                <Loader size={20} style={{ paddingTop: '3px' }} />
              </div>
            )}
            {resumeExists && (
              <div
                style={{
                  marginLeft: 'auto',
                  alignSelf: 'center',
                }}
              >
                {gptMessages.length > 0 ? (
                  <DownloadResumeButton />
                ) : (
                  <ReformatTextButton />
                )}
              </div>
            )}
          </div>
          {gptMessages.length === 0 && !resumeExists && (
            <div
              style={{
                color: 'gray',
                fontSize: '14px',
                marginLeft: '12px',
              }}
            >
              You can paste an existing resume text in the editor below to
              reformat it
            </div>
          )}
          {gptMessages.length > 0 && !resumeExists && (
            <div
              style={{
                color: 'gray',
                fontSize: '12px',
                marginLeft: '12px',
              }}
            >
              Initial Generation Should take ~1 minute
            </div>
          )}
          <div
            style={{
              opacity: everythingDisabled ? 0.4 : 1.0,
              pointerEvents: everythingDisabled ? 'none' : 'auto',
              maxHeight: '80vh',
              overflow: 'scroll',
              background: 'white',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #8080805c',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                fontSize: '12px',
              }}
            >
              {/* {renderPdf(focusedCandidate)} */}
              {/* <DocxPreview
                                name={resumeObj.full_name}
                                summaryText={resumeObj.summary_text}
                                educationEntries={resumeObj.education}
                                skills={resumeObj.skills}
                                experiences={resumeObj.experiences}
                                qualifications={resumeObj.certifications}
                            /> */}
              <ResumeQuillEditor
                name={resumeObj.full_name}
                summaryText={resumeObj.summary_text}
                educationEntries={resumeObj.education}
                skills={resumeObj.skills}
                experiences={resumeObj.experiences}
                qualifications={resumeObj.certifications}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                setOriginalEditorContent={setOriginalEditorContent}
                jobSkillsStrListLowered={jobSkillsStrListLowered}
                chatPanel={chatPanel}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeEditorPage;
