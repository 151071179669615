import React, { useEffect, useState } from 'react';
import { Table, Title, Button, Center, Loader } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import env from 'env';

import axios from '../../api/axiosConfig';
import { formatToLocalTime } from '../../utils/dateUtils';
import CreateCampaignModal from '../../components/campaign/CreateCampaignModal';

const CampaignsPage = () => {
  const navigate = useNavigate();

  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState(true);
  const [scriptsLoading, setScriptsLoading] = useState(true);

  // Making a good case for global storage / redux, I re-fetch the scripts on many pages
  const [scripts, setScripts] = useState([]);
  useEffect(() => {
    const fetchScripts = async () => {
      setScriptsLoading(true);
      try {
        const response = await axios.get(
          `/saved_scripts/org/${localStorage.getItem('orgId')}`
        );
        const scriptsResp = response.data;

        setScripts(Object.values(scriptsResp));
      } catch (error) {
        console.error('Failed to fetch scripts:', error);
      } finally {
        setScriptsLoading(false);
      }
    };

    fetchScripts();
  }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setCampaignsLoading(true);
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/campaigns/org/${localStorage.getItem('orgId')}`
        );
        const sortedData = response.data.campaigns.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        ); // Sort by created time, most recent first
        setCampaignsData(sortedData);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      } finally {
        setCampaignsLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  const navigateToCampaign = (campaignId) => {
    navigate(`/campaigns/campaign/${campaignId}`);
  };

  // TODO: make the headers sticky. Follow this https://ui.mantine.dev/category/tables/
  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title order={2} style={{ marginBottom: '20px' }}>
          Campaigns
        </Title>
        {scripts?.length > 0 && (
          <Button onClick={() => setCampaignModalOpen(true)}>
            New Campaign
          </Button>
        )}

        <CreateCampaignModal
          campaignModalOpen={campaignModalOpen}
          setCampaignModalOpen={setCampaignModalOpen}
          scripts={scripts}
        />
      </div>
      <div
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'scroll',
          background: 'white',
          borderRadius: '4px',
          border: '1px solid #8080805c',
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Start Time</Table.Th>
              <Table.Th>Campaign Name</Table.Th>
              <Table.Th>Progress</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Owner</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!(campaignsLoading || scriptsLoading) &&
              campaignsData.map((campaign, index) => (
                <Table.Tr
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigateToCampaign(campaign.campaign_id)}
                >
                  <Table.Td>{formatToLocalTime(campaign.created)}</Table.Td>
                  <Table.Td>{campaign.campaign_name}</Table.Td>
                  <Table.Td>
                    {campaign.total_calls - campaign.pending_calls} /{' '}
                    {campaign.total_calls}
                  </Table.Td>
                  <Table.Td>{campaign.status}</Table.Td>
                  <Table.Td style={{ color: '#505050' }}>
                    {campaign.user.email}
                  </Table.Td>
                </Table.Tr>
              ))}
            {(campaignsLoading || scriptsLoading) && (
              <tr>
                <td colSpan={7}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

export default CampaignsPage;
