import React, { useMemo } from 'react';
import {
  ActionIcon,
  Textarea,
  Button,
  Modal,
  Loader,
  Container,
  Grid,
  ScrollArea,
  CloseButton,
  Switch,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import SourcingLocationFilter from './SourcingLocationFilter';

const SourcingModal = ({
  jobDescriptionModalOpen,
  setJobDescriptionModalOpen,
  getJobSourceParameters,
  modalPage,
  analyzing,
  jobSummary,
  setJobSummary,
  fetchCandidates,
}) => {
  const Header3 = ({ children, ...props }) => {
    return (
      <h3 style={{ marginTop: 0 }} {...props}>
        {children}
      </h3>
    );
  };

  const Header4 = ({ children, ...props }) => {
    return (
      <h4 style={{ marginTop: 0 }} {...props}>
        {children}
      </h4>
    );
  };

  const updateJobDescription = (newDescription: string) => {
    setJobSummary((prevSummary) => ({
      ...prevSummary,
      jobDescription: newDescription,
    }));
  };

  const updateIdealCandidate = (newSummary: string) => {
    setJobSummary((prevSummary) => ({
      ...prevSummary,
      idealCandidateSummary: newSummary,
    }));
  };

  const renderColumn = (array, arrayIndex, name, fieldName, maxLength) => {
    const handleChange = (index, newValue) => {
      const newArray = [...array];
      newArray[index].value = newValue;
      setJobSummary((prev) => ({
        ...prev,
        [fieldName]: newArray,
      }));
    };

    const handleCheck = (index, newValue) => {
      const newArray = [...array];
      newArray[index].required = newValue;
      setJobSummary((prev) => ({
        ...prev,
        [fieldName]: newArray,
      }));
    };

    const handleRemove = (index) => {
      const newArray = [...array];
      newArray.splice(index, 1);
      setJobSummary((prev) => ({
        ...prev,
        [fieldName]: newArray,
      }));
    };

    const handleAdd = () => {
      const newArray = [...array, { value: '', required: false }]; // Add an empty object
      setJobSummary((prev) => ({
        ...prev,
        [fieldName]: newArray,
      }));
    };

    return (
      <>
        <div style={{ display: 'flex' }}>
          <Header3>{name}</Header3>
          {
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '12px',
                marginBottom: '18px',
              }}
            >
              <ActionIcon
                variant='filled'
                size='xs'
                aria-label='Add'
                onClick={handleAdd}
                disabled={array.length > maxLength}
              >
                <IconPlus />
              </ActionIcon>
            </div>
          }
        </div>

        <ScrollArea
          style={{
            height: '60vh',
            marginBottom: '10px',
            scrollbarWidth: 'none',
          }}
        >
          {array.map((item, index) => (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Textarea
                style={{
                  paddingRight: '10px',
                  width: '70%',
                }}
                key={index}
                value={item.value}
                minRows={1}
                placeholder=''
                autosize
                onChange={(e) => handleChange(index, e.target.value)}
              />

              <Switch
                checked={item.required}
                onChange={(event) =>
                  handleCheck(index, event.currentTarget.checked)
                }
              />
              <CloseButton
                onClick={() => handleRemove(index)}
                style={{
                  marginLeft: '12px',
                }}
              />
            </div>
          ))}
          {arrayIndex === 2 && (
            <div style={{}}>
              <Header4
                style={{
                  marginBottom: '6px',
                  marginTop: '0px',
                }}
              >
                {'Location'}
              </Header4>
              <SourcingLocationFilter
                locationFilterState={{
                  onChangeLocation: (location) => {
                    setJobSummary((prevSummary) => ({
                      ...prevSummary,
                      location: location,
                    }));
                  },
                  radius: jobSummary.radius,
                  onChangeRadius: (radius) => {
                    setJobSummary((prevSummary) => ({
                      ...prevSummary,
                      radius: radius,
                    }));
                  },
                  selectedLocation: jobSummary.location,
                }}
                onClear={() => {}}
                onClose={() => {}}
                pill={false}
              />
              <Header4 style={{ marginBottom: '6px' }}>{'Summary'}</Header4>
              <Textarea
                style={{
                  marginBottom: '10px',
                  paddingRight: '10px',
                }}
                key={'summary'}
                value={jobSummary.idealCandidateSummary}
                minRows={2}
                placeholder=''
                autosize
                size='xs'
                onChange={(e) => updateIdealCandidate(e.target.value)}
              />
            </div>
          )}
        </ScrollArea>
      </>
    );
  };

  const renderAnalyzing = useMemo(
    () => () => {
      return (
        <div
          style={{
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <h4>Analyzing</h4>

            <div
              style={{
                alignContent: 'center',
                marginLeft: '10px',
              }}
            >
              {analyzing && <Loader size={20} style={{ paddingTop: '3px' }} />}
            </div>
          </div>
        </div>
      );
    },
    [analyzing]
  );

  return (
    <Modal
      opened={jobDescriptionModalOpen}
      onClose={() => setJobDescriptionModalOpen(false)}
      size='100%'
      style={{ height: '80vh' }}
    >
      {modalPage === 0 ? (
        <>
          <Textarea
            autosize={true}
            minRows={20}
            size='sm'
            className='description-text-area'
            placeholder={'Enter job description'}
            value={jobSummary.jobDescription}
            onChange={(event) =>
              updateJobDescription(event.currentTarget.value)
            }
            style={{ height: '70vh' }}
          />
          {analyzing ? (
            renderAnalyzing()
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                size='lg'
                style={{
                  marginTop: '20px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                onClick={getJobSourceParameters}
              >
                Generate Search Paramaters
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <Container>
            <Grid style={{ justifyContent: 'space-between' }}>
              <Grid.Col span={4}>
                <Header3>{'Job Description'}</Header3>
                <Textarea
                  autosize={true}
                  minRows={20}
                  size='xs'
                  className='description-text-area'
                  placeholder={'Enter job description'}
                  value={jobSummary.jobDescription}
                  style={{ height: '60vh', fontSize: '10px' }}
                  onChange={(event) =>
                    updateJobDescription(event.currentTarget.value)
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {renderColumn(
                  jobSummary.skills,
                  1,
                  'Required Skills',
                  'skills',
                  15
                )}
              </Grid.Col>
              <Grid.Col span={4}>
                {renderColumn(
                  jobSummary.jobTitles,
                  2,
                  'Previous Job Titles',
                  'jobTitles',
                  4
                )}
              </Grid.Col>
            </Grid>
          </Container>

          {analyzing ? (
            renderAnalyzing()
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                size='lg'
                style={{
                  marginTop: '20px',
                  marginLeft: 'auto',
                  marginRight: '20px',
                }}
                onClick={getJobSourceParameters}
              >
                Re-generate Requirements
              </Button>
              <Button
                disabled={
                  jobSummary.idealCandidateSummary?.length < 10 ||
                  jobSummary?.skills?.length === 0 ||
                  !jobSummary?.location?.geometry?.location
                }
                size='lg'
                style={{
                  marginTop: '20px',
                  marginLeft: '20px',
                  marginRight: 'auto',
                }}
                onClick={fetchCandidates}
              >
                Search Candidates
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default SourcingModal;
