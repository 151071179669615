import React from 'react';
import { Title, Box, ScrollArea, Badge } from '@mantine/core';
import env from 'env';

import {
  RequirementImportance,
  IMPORTANCE_LABELS,
  IMPORTANCE_COLORS,
} from '../../pages/editorv2/EditorPageV2';
import './Transcript.css';

interface TranscriptProps {
  transcript: string;
  requirementGrades: Record<
    string,
    { answer: string; grade: string; importance: RequirementImportance }
  >;
  callId: string;
  smsTranscript?: string;
}

const Transcript: React.FC<TranscriptProps> = ({
  transcript,
  requirementGrades,
  callId,
  smsTranscript,
}) => (
  <Box className='transcript-container'>
    {callId && (
      <a
        href={`${env.REACT_APP_SERVER_URL}/download_recording/${callId}`}
        className='transcript-call-recording'
      >
        Download Recording
      </a>
    )}
    {smsTranscript && (
      <div className='transcript-column'>
        <Title order={3} className='column-title'>
          SMS Transcript
        </Title>
        <ScrollArea className='scrollable-content'>
          <div className='scroll-fade-top'></div>
          <div className='inside'>{smsTranscript}</div>
          <div className='scroll-fade-bottom'></div>
        </ScrollArea>
      </div>
    )}
    <div className='transcript-column'>
      <Title order={3} className='column-title'>
        Call Transcript
      </Title>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top'></div>
        <div className='inside'>{transcript}</div>
        <div className='scroll-fade-bottom'></div>
      </ScrollArea>
    </div>
    <div className='qa-column'>
      <Title order={3} className='column-title'>
        Questions & Answers
      </Title>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top'></div>
        <div className='inside'>
          {Object.entries(requirementGrades).map(
            ([question, { answer, grade, importance }], index) => (
              <div key={index} className='qa-item'>
                <Badge
                  color={IMPORTANCE_COLORS[importance]}
                  className='importanceBadge'
                >
                  {IMPORTANCE_LABELS[importance]}
                </Badge>

                <strong>
                  {'  '}
                  {question}
                </strong>
                <p className='grade'>Score: {grade}</p>
                <p className='answer'>{answer}</p>
              </div>
            )
          )}
        </div>
        <div className='scroll-fade-bottom'></div>
      </ScrollArea>
    </div>
  </Box>
);

export default Transcript;
