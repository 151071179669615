import React, { createRef, useState } from 'react';
import {
  Anchor,
  Button,
  Checkbox,
  FileInput,
  Flex,
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Modal,
  Divider,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import env from 'env';
import { Player } from '@lottiefiles/react-lottie-player';
import { notifications } from '@mantine/notifications';

import axios from '../../api/axiosConfig';

const CreateCampaignModal = ({
  campaignModalOpen,
  setCampaignModalOpen,
  scripts,
  forScriptInfo,
  forRequirements,
  callerSettingsProps,
}) => {
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = createRef();

  const [selectedScriptId, setSelectedScriptId] = useState('');
  const [campaignSuccess, setCampaignSuccess] = useState(false);

  const handleFileSelect = (event) => {
    const file = event;
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      const isCSV = extension === 'csv' || file.type === 'text/csv';
      if (isCSV) {
        setSelectedFile(file);
      } else {
        alert(
          'Only CSV files allowed. If you believe this is an error, please contact help@salv.ai'
        );
      }
    }
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e) => {
    preventDefaults(e);

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileSelect(files[0]);
    }
  };

  const handleDownloadExample = () => {
    window.location.href = `https://salvstorageaccount.blob.core.windows.net/public/example_call_list.csv`;
  };

  // quick-fix for now to sync new-script page to campaign page until we re-do the UI
  let callerSettings = callerSettingsProps;
  const callerSettingsForm = useForm({
    initialValues: {
      callFromNumber: '',
      useScriptScaffolding: true,
      LLMModel: 'faster',
      voiceName: 'Lisa (American)',
      rescheduleCallOnVoicemail: true,
      callRetryCount: 3,
      callRetryHours: 6,
    },
    validate: {
      voiceName: (v) => (v ? null : 'Voice required'),
    },
  });
  if (!callerSettings) {
    callerSettings = callerSettingsForm;
  }

  const campaignSettings = useForm({
    initialValues: {
      email: email,
      name: '',
      callNewApplicants: false,
      jobTitle: '',
      jobCity: '',
      jobState: '',
    },
    validate: {
      // checks whether value is a valid email
      email: (value) => {
        if (!value) return 'Email is required';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          return 'Invalid email address';
      },
      name: (v) => (v ? null : 'Name required'),
    },
  });

  const scriptInfo = (() => {
    if (forScriptInfo && forRequirements) {
      forScriptInfo['requirements'] = forRequirements;
      return forScriptInfo;
    }
    return (
      scripts.find((s) => s.script_id === selectedScriptId)?.script_info ||
      undefined
    );
  })();

  const handleSubmit = () => {
    if (!selectedFile) {
      return;
    }
    if (
      scriptInfo.scheduleFollowUp &&
      !scriptInfo.callOutro.includes('{book_call}')
    ) {
      alert(
        'Please include "{book_call}" in your script to schedule a follow-up or deselect "Schedule follow up" option.'
      );
      return;
    }

    setLoading(true);

    const {
      backgroundInfo,
      failedCallSms,
      callIntro,
      callOutro,
      requirements,
      scheduleFollowUp,
      passingScore,
    } = scriptInfo;
    // TODO: instead of appending all this info, just pass the script_id and let the backend handle the rest
    const {
      email,
      name: campaignName,
      jobTitle,
      jobCity,
      jobState,
    } = campaignSettings.values;
    const {
      voiceName,
      rescheduleCallOnVoicemail,
      callRetryCount,
      callRetryHours,
      callFromNumber,
      useScriptScaffolding,
    } = callerSettings.values;
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('email', email);
    formData.append('campaign_name', campaignName);
    formData.append('call_intro', callIntro);
    formData.append('call_outro', callOutro);
    formData.append('passing_score', passingScore);
    formData.append(
      'candidate_requirements',
      JSON.stringify(
        requirements?.map(
          ({ question, llmGradingInstructions, importance }) => ({
            question,
            llm_grading_instructions: llmGradingInstructions,
            importance,
          })
        )
      )
    ); // already stringified
    formData.append('voice_name', voiceName);
    formData.append('sms_message', failedCallSms);
    formData.append('schedule_follow_up', JSON.stringify(scheduleFollowUp));
    formData.append('background_info', backgroundInfo);
    formData.append(
      'use_script_scaffolding',
      JSON.stringify(useScriptScaffolding)
    );
    formData.append('call_from_number', callFromNumber);
    // formData.append('LLM_model', LLMModel);
    formData.append('job_title', jobTitle);
    formData.append('job_city', jobCity);
    formData.append('job_state', jobState);
    formData.append(
      'reschedule_call_on_voicemail',
      JSON.stringify(rescheduleCallOnVoicemail)
    );
    formData.append('call_retry_count', JSON.stringify(callRetryCount));
    formData.append('call_retry_hours', JSON.stringify(callRetryHours));

    axios
      .post(`${env.REACT_APP_SERVER_URL}/start_campaign`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        setCampaignSuccess(true);
      })
      .catch((e) => {
        notifications.show({
          title: 'Failed to create campaign',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      opened={campaignModalOpen}
      onClose={() => {
        setCampaignModalOpen(false);
        setCampaignSuccess(false);
      }}
      title='New Campaign'
      size='xl'
    >
      <Stack justify='space-between' h='100%'>
        <Divider style={{ margin: '10px' }} />
        {!forScriptInfo && scripts && (
          <Select
            label='Call Script'
            data={scripts.map((s) => {
              return { label: s.script_info.name, value: s.script_id };
            })}
            placeholder='Select an Existing Script'
            nothingFoundMessage='No scripts available'
            value={selectedScriptId}
            onChange={setSelectedScriptId}
            required
          />
        )}

        {!campaignSuccess ? (
          <>
            <SimpleGrid cols={2} style={{ gap: '40px' }}>
              <Stack>
                <TextInput
                  label='Campaign Name:'
                  placeholder='Enter campaign name'
                  required
                  {...campaignSettings.getInputProps('name')}
                />
                <div onDragOver={preventDefaults} onDrop={handleFileDrop}>
                  <FileInput
                    placeholder='Upload CSV'
                    label='Upload candidates csv file'
                    required
                    value={selectedFile}
                    ref={fileInputRef}
                    error={!selectedFile}
                    onChange={handleFileSelect}
                  />
                  <Anchor size='sm' onClick={handleDownloadExample}>
                    Download Example CSV
                  </Anchor>
                </div>
                <TextInput
                  label='Email for campaign results'
                  placeholder='Your email'
                  required
                  {...campaignSettings.getInputProps('email')}
                />
                {isInternal && (
                  <div>
                    <Checkbox
                      style={{
                        marginBottom: '10px',
                        marginTop: '20px',
                      }}
                      label='Call New Indeed Applicants'
                      {...campaignSettings.getInputProps('callNewApplicants')}
                    />
                    <TextInput
                      label='Indeed Job Title, City, State (must match exactly)'
                      placeholder='CNC Machinist'
                      disabled={!campaignSettings.values.callNewApplicants}
                      required
                      {...campaignSettings.getInputProps('jobTitle')}
                    />
                    <div style={{ display: 'flex' }}>
                      <TextInput
                        style={{
                          marginBottom: '10px',
                          width: '80%',
                          marginRight: '4px',
                        }}
                        label=''
                        placeholder='Greenville'
                        disabled={!campaignSettings.values.callNewApplicants}
                        required
                        {...campaignSettings.getInputProps('jobCity')}
                      />
                      <TextInput
                        style={{
                          marginBottom: '10px',
                          width: '20%',
                        }}
                        label=''
                        placeholder='SC'
                        disabled={!campaignSettings.values.callNewApplicants}
                        required
                        {...campaignSettings.getInputProps('jobState')}
                      />
                    </div>
                  </div>
                )}
              </Stack>
              <Stack>
                {isInternal && (
                  <>
                    <TextInput
                      label='Call From Number:'
                      {...callerSettings.getInputProps('callFromNumber')}
                    />
                    <Switch
                      label='Use Script Scaffolding'
                      {...callerSettings.getInputProps('useScriptScaffolding')}
                    />
                  </>
                )}
                {/*
                  <Popover opened={openedCalendarPopover}>
                    <Popover.Target>
                      <Switch
                        onMouseOver={openCalendarPopover}
                        onMouseLeave={closeCalendarPopover}
                        label='Schedule follow up'
                        {...callerSettings.getInputProps('scheduleFollowUp')}
                      />
                    </Popover.Target>
                    <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                      <Text size='xs'>
                        Use "&#123;book_call&#125;" for the line where the AI
                        should get the candidate's availability and book another
                        call.
                        <br />
                        Example Script:
                        <br />
                        ...
                        <br />
                        <br />
                        So next steps would be to schedule a time for you to
                        talk with my partner Jesse. How does that sound?
                        <br />
                        <br />
                        &#123;book_call&#125;
                        <br />
                        <br />
                        Now that we're all set. I hope your call with Jesse goes
                        great and you have a wonderful rest of your day!
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                  <Tooltip label='Which LLM to use'>
                    <SegmentedControl
                      data={[
                        { label: 'Faster', value: 'faster' },
                        { label: 'Smarter', value: 'smarter' },
                      ]}
                      {...callerSettings.getInputProps('LLMModel')}
                    />
                  </Tooltip>
                  <Select
                    label='Voice'
                    data={[
                      'Lisa (American)',
                      'George (American)',
                      'Abby (British)',
                      'William (British)',
                    ]}
                    placeholder='Select a voice'
                    searchable
                    nothingFoundMessage='No matching voices'
                    {...callerSettings.getInputProps('voiceName')}
                  /> */}
                <Select
                  label='Voice'
                  data={[
                    'Lisa (American)',
                    'George (American)',
                    'Abby (British)',
                    'William (British)',
                  ]}
                  placeholder='Select a voice'
                  nothingFoundMessage='No matching voices'
                  {...callerSettings.getInputProps('voiceName')}
                  allowDeselect={false}
                />
                <Group
                  style={{
                    border: '1px solid #80808066',
                    padding: '16px',
                    borderRadius: '12px',
                    marginTop: '20px',
                  }}
                >
                  <Switch
                    label='Auto-reschedule on voicemail'
                    checked={callerSettings.values.rescheduleCallOnVoicemail}
                    {...callerSettings.getInputProps(
                      'rescheduleCallOnVoicemail'
                    )}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      gap: '20px',
                    }}
                  >
                    <NumberInput
                      w={80}
                      label='# of retries'
                      disabled={
                        !callerSettings.values.rescheduleCallOnVoicemail
                      }
                      min={1}
                      max={5}
                      allowDecimal={false}
                      {...callerSettings.getInputProps('callRetryCount')}
                    />
                    <NumberInput
                      w={100}
                      label='Call again after'
                      disabled={
                        !callerSettings.values.rescheduleCallOnVoicemail
                      }
                      allowNegative={false}
                      rightSection='hours'
                      rightSectionWidth='max-content'
                      {...callerSettings.getInputProps('callRetryHours')}
                    />
                  </div>
                </Group>
              </Stack>
            </SimpleGrid>
            <Flex justify='center' gap='md'>
              <Button
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  !callerSettings.isValid() ||
                  !campaignSettings.isValid() ||
                  !scriptInfo ||
                  !selectedFile
                }
              >
                Launch Campaign
              </Button>
            </Flex>
          </>
        ) : (
          <Flex
            justify='center'
            align='center'
            direction='column'
            gap='md'
            style={{ minHeight: '340px' }}
          >
            <div style={{ position: 'absolute' }}>
              <Player
                autoplay
                loop={true}
                src='/confetti.json' // Path to your confetti animation
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <h1
              style={{
                textAlign: 'center',
                marginTop: '0px',
                top: '-10px',
                position: 'relative',
              }}
            >
              Campaign Launched Successfully 🚀
            </h1>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='48'
              fill='green'
              className='bi bi-check-circle-fill'
              viewBox='0 0 16 16'
            >
              <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.93 10.58l-2.47-2.47-.708.708 3.18 3.18 6.364-6.364-.708-.708L6.93 10.58z' />
            </svg>
          </Flex>
        )}
      </Stack>
    </Modal>
  );
};

export default CreateCampaignModal;
