import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import {
  ActionIcon,
  Switch,
  Alert,
  Badge,
  Slider,
  NumberInput,
  Button,
  Card,
  Center,
  ComboboxData,
  Flex,
  Group,
  Input,
  MantineColor,
  NavLink,
  Paper,
  Select,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  Title,
  Box,
  Modal,
  Divider,
  Loader,
} from '@mantine/core';
import './EditorPageV2.css';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconCheck,
  IconExclamationCircle,
  IconPencil,
  IconPhone,
  IconPlus,
  IconTrash,
  IconRocket,
  IconHelp,
} from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { IconGripVertical } from '@tabler/icons-react';
import { useListState } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import env from 'env';

import { getOutroFromSchedule } from './Outro';
import axios from '../../api/axiosConfig';
import Transcript from '../../components/transcript/Transcript';
import CreateCampaignModal from '../../components/campaign/CreateCampaignModal';

export enum RequirementImportance {
  Major = 'MAJOR',
  Normal = 'NORMAL',
  Minor = 'MINOR',
  Info = 'INFO',
}

export const IMPORTANCE_LABELS: Record<RequirementImportance, string> = {
  [RequirementImportance.Major]: 'Major',
  [RequirementImportance.Normal]: 'Normal',
  [RequirementImportance.Minor]: 'Minor',
  [RequirementImportance.Info]: 'Info',
};

export const IMPORTANCE_COLORS: Record<RequirementImportance, MantineColor> = {
  [RequirementImportance.Major]: 'red',
  [RequirementImportance.Normal]: 'orange',
  [RequirementImportance.Minor]: 'yellow',
  [RequirementImportance.Info]: 'blue',
};

const IMPORTANCE_OPTIONS: ComboboxData = Object.entries(IMPORTANCE_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const ORG_BACKGROUND_INFO_PLACEHOLDER = `Example:
- Our company is called Salv AI. It is headquarted in San Francisco, CA.
- We specialize in building automated recruiting solutions
- Our corporate phone number is  877-527-7454
- If the candidate asks about benefits, say we offer a comprehensive benefits package including medical, dental, and vision. A recruiter can go more in-depth on a follow-up call`;

interface CandidateRequirement {
  question: string;
  importance: RequirementImportance;
  llmGradingInstructions?: string;
}

export default function EditorPageV2() {
  const location = useLocation();
  const match = location.pathname.match(/\/scripts\/script-editor\/([^/]+)/);
  const scriptId = match ? match[1] : null;

  // scriptFetched is set when it's actually fetched
  // needsReFetch is set if we need a re-fetch (immediately after first fetch)
  const [scriptFetched, setScriptFetched] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedBackgroundInfoChanges, setUnsavedBackgroundInfoChanges] =
    useState(false);

  // just a UI hack so we don't show 'saving' when the script first loads

  const UNTITLED_SCRIPT = 'Untitled Script';
  const isInitialFetch = useRef(true);
  const needsReFetch = useRef(true);

  let lastTestPhoneNumber = localStorage.getItem('lastTestPhoneNumber') || '';
  let lastTestPhoneName = localStorage.getItem('lastTestPhoneName') || '';
  const [activeStep, setActiveStep] = useState<number>(0);
  const orgId = localStorage.getItem('orgId');

  const [confirmEditCompanyModalOpen, setConfirmEditCompanyModalOpen] =
    useState(false);
  const [editngCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [companyInfoEdits, setCompanyInfoEdits] = useState('');

  const [newMessage, setNewMessage] = useState<string>('');
  const [msgEditIndex, setMsgEditIndex] = useState<number | null>(null);

  const orgBackgroundInfo = useForm({
    initialValues: {
      callOrgBackgroundInfo: '',
      backgroundInfoId: '' + uuidv4(),
    },
  });

  // Note: no longer a form
  const callerSettings = useForm({
    initialValues: {
      callFromNumber: '',
      useScriptScaffolding: true,
      LLMModel: 'faster',
      voiceName: 'Lisa (American)',
      rescheduleCallOnVoicemail: true,
      callRetryCount: 3,
      callRetryHours: 6,
    },
  });
  const testCallInfo = useForm({
    initialValues: {
      candidateName: lastTestPhoneName,
      phoneNumber: lastTestPhoneNumber,
    },
  });

  const [requirements, requirementsHandlers] =
    useListState<CandidateRequirement>([]);
  const [generatingScript, setGeneratingScript] = useState<boolean>(false);
  const [requirementBeingEdited, setRequirementBeingEdited] = useState<
    number | undefined
  >();

  const [testCallActive, setTestCallActive] = useState<boolean>(false);
  const [canSubmitCampaign, setCanSubmitCampaign] = useState<boolean>(false);
  const [testCallModalOpen, setTestCallModalOpen] = useState<boolean>(false);
  const [campaginModalOpen, setCampaignModalOpen] = useState<boolean>(false);

  const [candidateTimezone, setCandidateTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const defaultScheduleFollowUp =
    !!localStorage.getItem('calAPIKey') &&
    !!localStorage.getItem('calEventTypeID');
  const scriptInfo = useForm({
    initialValues: {
      jobDescription: '',
      callIntro: '',
      backgroundInfo: '',
      callOutro: getOutroFromSchedule(defaultScheduleFollowUp).actualValue,
      failedCallSms: [] as string[],
      name: UNTITLED_SCRIPT,
      scriptId: '' + uuidv4(),
      scheduleFollowUp: defaultScheduleFollowUp,
      passingScore: 60,
    },
  });

  const getMessagesArray = useCallback(
    (messages: string | string[]): string[] => {
      return Array.isArray(messages)
        ? messages
        : messages.split('-').filter((msg) => msg.trim() !== '');
    },
    []
  );

  const handleAddMessage = useCallback(() => {
    if (newMessage.trim()) {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages, newMessage];
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setNewMessage('');
    }
  }, [newMessage, scriptInfo, getMessagesArray]);

  const handleRemoveMessage = useCallback(
    (index: number) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = currentMessages.filter((_, i) => i !== index);
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
    },
    [scriptInfo, getMessagesArray]
  );

  const handleEditMessage = useCallback((index: number) => {
    setMsgEditIndex(index);
  }, []);

  const handleSaveMessage = useCallback(
    (index: number, editedMessage: string) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages];
      updatedMessages[index] = editedMessage;
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setMsgEditIndex(null);
    },
    [scriptInfo, getMessagesArray]
  );

  const messages = getMessagesArray(scriptInfo.values.failedCallSms);

  useEffect(() => {
    setScriptFetched(false);
    needsReFetch.current = true;
  }, [scriptId, setScriptFetched]);

  useEffect(() => {
    const fetchScript = async () => {
      if (scriptFetched || !needsReFetch.current) {
        return;
      }
      needsReFetch.current = false;
      const setInitialFetchFalse = () => {
        isInitialFetch.current = false;
      };
      const setInitialFetchFalseAfterTimeout = () => {
        setTimeout(setInitialFetchFalse, 2000);
      };

      try {
        const backgroundInfoResp = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`
        );
        // Always fetch the backgroudn info
        console.log('backgroundInfoResp', backgroundInfoResp);
        if (backgroundInfoResp?.data) {
          orgBackgroundInfo.setValues({
            callOrgBackgroundInfo:
              backgroundInfoResp?.data?.callOrgBackgroundInfo || '',
          });
          setCompanyInfoEdits(
            backgroundInfoResp?.data?.callOrgBackgroundInfo || ''
          );
        }
        if (!scriptId) {
          // if there is no script ID, don't fetch the script
          scriptInfo.reset();
          requirementsHandlers.setState([]);
          setScriptFetched(true);
          setEditingName(true);
          setInitialFetchFalseAfterTimeout();
          return;
        }
        const scriptResponse = await axios.get(
          `${env.REACT_APP_SERVER_URL}/script/${scriptId}`
        );
        const { script_info } = scriptResponse.data;

        const infoToSet = {
          callIntro: script_info.callIntro,
          backgroundInfo: script_info.backgroundInfo,
          callOutro: script_info.callOutro,
          failedCallSms: script_info.failedCallSms,
          name: script_info.name,
          scriptId: script_info.scriptId,
          jobDescription: script_info.jobDescription,
          passingScore:
            typeof script_info.passingScore === 'number'
              ? script_info.passingScore
              : 60,
        };
        scriptInfo.setValues(infoToSet);
        requirementsHandlers.setState(script_info.requirements);
        scriptInfo.setFieldValue(
          'scheduleFollowUp',
          !!script_info.scheduleFollowUp
        );
        callerSettings.setFieldValue(
          'voiceName',
          script_info.voiceName || 'Lisa (American)'
        );
        setScriptFetched(true);
        // this is somewhat hacky, we set this initial fetch done after timeout
        // so that we only "save" on state diffs we are confident are triggered not by the initial fetch
        // it's just a qol thing so that the user doesn't see an uneeded "save" when they first open the script while the state is setting
        // This is because the state changing is what triggers the useEffects
        // It's technically a potential race condition but should barely ever happen and I think this is the cleanest solution code-wise
        // + it's not too bad when it triggeres since it would only "save" the successfullt fetched values anyways
        // The one actually bad case is when BOTH setStates take > 2 seconds, one fails, one succeeds, and then the update is only partial,
        // Using useStates are tricky due to race conditions between set states and setstates triggering the save useEffects

        // TODO: I believe the only non-timeout hack solution is to track if each state value has been independently set to the value the backend returned
        // , doubly checking the backend return values with useRefs, and that the backend fetch succeeded.
        // something like
        // if (backendFetchedRef.current && valueRefAFromBackend.current === stateValueForA && sameForBB && sameForC..)
        //      then saving A,B,C allowed (A, B, C being parts of the script maintained as independent variables)
        // For now, the timeouts should be good enough since setState should never take more than a few millieconds

        // Note to future developer: you must be very careful when to use refs and state. If you try to use state variables in the useEffect or useaCallback that triggers saves,
        // changing those variables will cause the saves to fire
        setInitialFetchFalseAfterTimeout();
      } catch (error: unknown) {
        notifications.show({
          title: 'There was an error retrieving your script. Editing Disabled',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        console.error('Error fetching campaign details:', error);
      } finally {
      }
    };
    const debouncedFetchScript = debounce(() => {
      fetchScript();
    }, 250);
    debouncedFetchScript();
  }, [
    scriptId,
    scriptInfo,
    orgBackgroundInfo,
    scriptFetched,
    setScriptFetched,
    requirementsHandlers,
    callerSettings,
    orgId,
  ]);

  const handleDeleteRequirement = useCallback(
    (i: number) => () => {
      setRequirementBeingEdited(undefined);
      requirementsHandlers.remove(i);
    },
    [requirementsHandlers]
  );

  const handleGenerateScript = useCallback(() => {
    setGeneratingScript(true);
    axios
      .post(`${env.REACT_APP_SERVER_URL}/generate_script`, {
        jobDescription: scriptInfo.values.jobDescription,
      })
      .then((response) => {
        scriptInfo.setValues({
          callIntro: response.data.call_intro,
          backgroundInfo: `START_JOB_DESCRIPTION\n${scriptInfo.values.jobDescription}\nEND_JOB_DESCRIPTION`,
          failedCallSms: response.data.failed_call_sms,
        });
        requirementsHandlers.setState(
          response.data.requirements.map((requirement) => ({
            question: requirement.question,
            llmGradingInstructions: requirement.llm_grading_instructions,
            importance: RequirementImportance.Normal,
          })) || []
        );
        setActiveStep(activeStep + 1);
      })
      .catch((e) => {
        notifications.show({
          title: 'Error generating call script',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setGeneratingScript(false);
      });
  }, [activeStep, requirementsHandlers, scriptInfo]);

  const handleClickGenerateScript = useCallback(() => {
    const hasExistingContent =
      Object.entries(scriptInfo.values)
        .filter(
          ([key]) =>
            key !== 'jobDescription' &&
            key !== 'name' &&
            key !== 'callOutro' &&
            key !== 'scriptId' &&
            key !== 'requirements'
        )
        .some(([, value]) => !!value) || requirements.length > 0;
    console.log(requirements.length);
    console.log(scriptInfo.values);
    if (hasExistingContent) {
      modals.openConfirmModal({
        title: 'Generate Script Info from Job Description',
        children: (
          <Alert color='red' icon={<IconExclamationCircle size={16} />}>
            Warning: this action will overwrite your existing script and
            requirements. Are you sure you want to proceed?
          </Alert>
        ),
        labels: { confirm: 'Generate', cancel: 'Cancel' },
        onConfirm: handleGenerateScript,
      });
    } else {
      handleGenerateScript();
    }
  }, [handleGenerateScript, requirements.length, scriptInfo.values]);

  const [callId, setCallId] = useState<string | null>(null);
  const [callStatusMsg, setCallStatusMsg] = useState<string>('');
  const [transcript, setTranscript] = useState<string>('');
  const [requirementGrades, setRequirementGrades] = useState<any>({});

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  useEffect(() => {
    if (testCallActive === false && transcript) {
      openTranscript();
    }
  }, [testCallActive, transcript, openTranscript]);

  useEffect(() => {
    let ws: WebSocket | null = null;
    if (callId && testCallActive) {
      ws = new WebSocket(`${env.REACT_APP_WS_URL}/ws/call_status/${callId}`);

      ws.onopen = () => {
        console.log('WebSocket Connected for callId:', callId);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'event_transcript') {
          setTranscript(data.transcript);
          setRequirementGrades(data.requirement_grades);
          setTestCallActive(false);
        } else if (data.type === 'event_phone_call_ended') {
          setCallStatusMsg('Call complete. Extracting answers...');
        } else if (data.type === 'event_phone_call_connected') {
          setCallStatusMsg(
            `On the phone with ${testCallInfo.values.candidateName}...`
          );
        }
        console.log(data);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket Disconnected for callId:', callId);
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [callId, testCallActive, testCallInfo.values.candidateName]);

  const msgTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (msgTextareaRef.current && msgEditIndex !== -1) {
      msgTextareaRef.current.focus();
      const value = msgTextareaRef.current.value;
      if (value) {
        msgTextareaRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [msgEditIndex]);

  const handleScheduleFollowUpChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.checked;

      if (
        newValue &&
        (!localStorage.getItem('calAPIKey') ||
          !localStorage.getItem('calEventTypeID'))
      ) {
        notifications.show({
          id: 'calendar-integration-required',
          title: 'Calendar Integration Required',
          message:
            'Please set up your calendar integration to use the "Schedule follow up" option.',
          color: 'red',
        });
        return;
      }

      scriptInfo.setFieldValue('scheduleFollowUp', newValue);

      const defaultCallOutro = getOutroFromSchedule(newValue).actualValue;
      scriptInfo.setFieldValue('callOutro', defaultCallOutro);
    },
    [scriptInfo]
  );

  const handleTestCall = async (e: React.FormEvent) => {
    e.preventDefault();

    if (verifyCallOutro()) {
      return;
    }

    localStorage.setItem(
      'lastTestPhoneName',
      testCallInfo.values.candidateName
    );
    localStorage.setItem(
      'lastTestPhoneNumber',
      testCallInfo.values.phoneNumber
    );

    setTestCallActive(true);
    setCallStatusMsg(`Setting up voice agent...`);

    const payload: Record<string, any> = {
      callIntro: scriptInfo.values.callIntro,
      callOutro: scriptInfo.values.callOutro,
      candidateRequirements: JSON.stringify(requirements),
      candidateName: testCallInfo.values.candidateName,
      phoneNumber: testCallInfo.values.phoneNumber,
      voiceName: callerSettings.values.voiceName,
      failedCallSms: messages,
      scheduleFollowUp: scriptInfo.values.scheduleFollowUp,
      backgroundInfo: scriptInfo.values.backgroundInfo,
      useScriptScaffolding: callerSettings.values.useScriptScaffolding,
      callFromNumber: callerSettings.values.callFromNumber,
      passingScore: scriptInfo.values.passingScore,
      LLMModel: callerSettings.values.LLMModel,
      rescheduleCallOnVoicemail:
        callerSettings.values.rescheduleCallOnVoicemail,
      callRetryHours: callerSettings.values.callRetryHours,
      callRetryCount: callerSettings.values.callRetryCount,
    };

    if (scriptInfo.values.scheduleFollowUp && candidateTimezone) {
      payload.candidateTimezone = candidateTimezone;
    }

    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/test_call`,
        payload
      );
      console.log(`setting call_id ${response.data.data.call_id}`);
      setCallId(response.data.data.call_id);
      setCallStatusMsg(`Calling ${testCallInfo.values.candidateName}...`);
    } catch (error) {
      console.error('Error initiating call:', error);
      setTestCallActive(false);
      setCallStatusMsg('Failed to initiate call. Please try again.');
    }
  };

  function CallStatus() {
    const closeButtonHeight = 40;

    return (
      <>
        {testCallActive && <div className='modal-overlay' />}
        <Modal
          opened={testCallActive}
          onClose={() => setTestCallActive(false)}
          centered
          size='lg'
          withCloseButton
        >
          <Center
            style={{
              height: `calc(100% - ${closeButtonHeight}px)`,
              paddingBottom: `${closeButtonHeight}px`,
            }}
          >
            <Text ta='center' fw={700} style={{ fontSize: '40px' }}>
              {callStatusMsg}
            </Text>
          </Center>
        </Modal>
      </>
    );
  }

  const noChanges = useMemo(() => {
    const { callIntro, backgroundInfo, failedCallSms, name } =
      scriptInfo.values;
    return (
      name === UNTITLED_SCRIPT &&
      !callIntro &&
      !backgroundInfo &&
      !failedCallSms &&
      !requirements?.length
    );
  }, [scriptInfo.values, requirements?.length]);

  const saveScript = useCallback(async () => {
    // Make a POST request to /edit_script with the existing script's details

    const {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      name,
      scriptId,
      passingScore,
    } = scriptInfo.values;
    const payload = {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      name,
      scriptId,
      passingScore,
      requirements: requirements,
      scheduleFollowUp: scriptInfo.values.scheduleFollowUp,
      voiceName: callerSettings.values.voiceName,
    };

    if (noChanges) {
      setUnsavedChanges(false);
      // If no edits made, don't save progress
      return;
    }

    setUnsavedChanges(true);

    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/saved_scripts`, payload);
      setUnsavedChanges(false);
    } catch (error) {
      console.error('Error updating script:', error);
    }
  }, [
    requirements,
    scriptInfo.values,
    noChanges,
    callerSettings.values.voiceName,
  ]);

  useEffect(() => {
    if (noChanges || isInitialFetch.current) {
      return;
    }
    setUnsavedChanges(true);
    const debouncedSaveScript = debounce(saveScript, 1000);

    debouncedSaveScript();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveScript.cancel();
    };
  }, [saveScript, noChanges]);

  const saveBackgroundInfo = useCallback(async () => {
    // Make a POST request to /edit_script with the existing script's details

    const { callOrgBackgroundInfo } = orgBackgroundInfo.values;
    const payload = {
      callOrgBackgroundInfo,
      orgId,
    };

    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/org_background_info`,
        payload
      );
      setUnsavedBackgroundInfoChanges(false);
      // I find this slightly disconcerting and redundant to the save
      // notifications.show({
      //   title: 'Company update successful',
      //   message:
      //     'Company background information updated successfully for all scripts in your organization.',
      //   color: 'green',
      // });
    } catch (error) {
      console.error('Error saving backgroundinfo:', error);
    }
  }, [orgBackgroundInfo.values, orgId]);

  useEffect(() => {
    if (isInitialFetch.current) {
      return;
    }

    setUnsavedBackgroundInfoChanges(true);
    const debouncedSaveBackgroundInfo = debounce(saveBackgroundInfo, 2000);

    debouncedSaveBackgroundInfo();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveBackgroundInfo.cancel();
    };
  }, [saveBackgroundInfo]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const table = document.querySelector('.mantine-Table-tbody');

      // Check if the target is not within the table container
      if (table && !table.contains(e.target as Node)) {
        setRequirementBeingEdited(undefined);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const verifyCallOutro = () => {
    if (
      scriptInfo.values.scheduleFollowUp &&
      (!localStorage.getItem('calAPIKey') ||
        !localStorage.getItem('calEventTypeID'))
    ) {
      notifications.show({
        title: 'Calendar Integration Required',
        message:
          'Please set up your Calendar Integration or deselect "Schedule Follow Up" Call Outro option.',
        color: 'red',
      });
      return true;
    }

    if (
      scriptInfo.values.scheduleFollowUp &&
      !scriptInfo.values.callOutro.includes('{book_call}')
    ) {
      notifications.show({
        title: '{book_call} Required',
        message:
          'Please include "{book_call}" in your Call Outro or deselect the "Schedule Follow Up" option.',
        color: 'red',
      });
      return true;
    }
    return false;
  };

  const handleDragStart = () => {
    // Exit editing mode when starting to drag
    setRequirementBeingEdited(undefined);
  };

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      requirementsHandlers.reorder({
        from: result.source.index,
        to: result.destination.index,
      });
    },
    [requirementsHandlers]
  );

  const handleRowClick = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setRequirementBeingEdited((current) =>
      current === index ? undefined : index
    );
  };

  const areUnsavedCompanyInfoDiffs =
    companyInfoEdits !== orgBackgroundInfo.values.callOrgBackgroundInfo;

  const steps: {
    label: string;
    description?: string;
    indent?: boolean;
    isComplete: boolean;
    element?: React.ReactNode;
  }[] = useMemo(() => {
    const handlePreviousStep = () => {
      setActiveStep(activeStep - 1);
    };
    const handleNextStep = () => {
      setActiveStep(activeStep + 1);
    };

    const handleAddRequirement = () => {
      requirementsHandlers.append({
        question: '',
        llmGradingInstructions: '',
        importance: RequirementImportance.Normal,
      });
      setRequirementBeingEdited(requirements.length);
    };
    const handleFieldChange =
      (index: number, field: keyof CandidateRequirement) =>
      (value: string | RequirementImportance) => {
        requirementsHandlers.setItemProp(index, field, value);
      };

    return [
      {
        label: 'Job Description (Optional)',
        description: 'Auto-generate a call script and requirements',
        isComplete: true,
        element: (
          <>
            <Title order={5}>Job Description (optional)</Title>
            <Text fz='sm' c='dimmed'>
              Add a job description which can be used to generate a call script
              and requirements
            </Text>
            <Textarea
              className='fullHeightTextarea'
              placeholder='Paste in complete job description...'
              {...scriptInfo.getInputProps('jobDescription')}
              disabled={generatingScript}
            />
            <Flex
              h='100%'
              justify='flex-end'
              align='flex-end'
              gap='md'
              style={{ paddingBottom: '18px' }}
            >
              <Button variant='outline' onClick={handleNextStep}>
                Skip
              </Button>
              <Button
                onClick={handleClickGenerateScript}
                loading={generatingScript}
                disabled={!scriptInfo.values.jobDescription}
              >
                Generate Script and Requirements
              </Button>
            </Flex>
          </>
        ),
      },
      {
        label: 'Call Script (Preview)',
        isComplete: true,
        element: (
          <Stack h='100%'>
            <Stack>
              <Title order={3}>Script Preview</Title>
              <Title order={5}>Intro</Title>
              <Text
                size='sm'
                style={{
                  wordWrap: 'break-word' /* Break long words */,
                  wordBreak: 'break-all' /* Break all lines */,
                  whiteSpace: 'pre-wrap' /* Allow text to wrap */,
                }}
              >
                {renderWithWaitLines(scriptInfo.values.callIntro)}
              </Text>
              <Title order={5}>Screening Questions</Title>
              <div>
                {requirements?.map((requirement, i) => (
                  <Fragment key={i}>
                    <Text size='sm'>{requirement.question}</Text>
                    {
                      <Text size='xs' c='gray' className='wait-for-response'>
                        Wait for response
                      </Text>
                    }
                  </Fragment>
                ))}
              </div>
              <Title order={5}>Outro</Title>
              <Text
                size='sm'
                style={{
                  wordWrap: 'break-word' /* Break long words */,
                  wordBreak: 'break-all' /* Break all lines */,
                  whiteSpace: 'pre-wrap' /* Allow text to wrap */,
                }}
              >
                {
                  getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
                    .previewDisplay
                }
              </Text>
            </Stack>
            <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
              <Button variant='outline' onClick={handlePreviousStep}>
                Previous
              </Button>
              <Button onClick={handleNextStep}>Next</Button>
            </Flex>
          </Stack>
        ),
      },
      {
        label: 'Call Intro',
        isComplete: !!scriptInfo.values.callIntro,
        indent: true,
        element: (
          <div className='evenRows'>
            <Stack h='100%'>
              <Title order={5}>Call Intro</Title>
              <Text fz='sm' c='dimmed'>
                This message will be used at the beginning of the call to
                introduce the candidate to the company and role.
              </Text>

              <Textarea
                className='halfHeightTextarea'
                {...scriptInfo.getInputProps('callIntro')}
              />
              <Flex
                h='100%'
                justify='flex-end'
                align='flex-end'
                gap='md'
                className='paddingBottom'
              >
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </Stack>
          </div>
        ),
      },
      {
        label: 'Screening Questions',
        isComplete:
          requirements?.length !== 0 &&
          requirements?.every((req) => !!req.question),
        indent: true,
        element: (
          <>
            <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <Table className='requirementTable'>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th>
                      Question
                      <Input.Label required />
                    </Table.Th>
                    <Table.Th>Grading Instructions</Table.Th>
                    <Table.Th>Importance</Table.Th>
                    <Table.Th />
                  </Table.Tr>
                </Table.Thead>
                <Droppable droppableId='requirements'>
                  {(provided) => (
                    <Table.Tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {requirements?.map(
                        (
                          { question, llmGradingInstructions, importance },
                          index
                        ) => {
                          const editing = requirementBeingEdited === index;

                          return (
                            <Draggable
                              key={index}
                              draggableId={`req-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Table.Tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    background: snapshot.isDragging
                                      ? 'rgba(0, 0, 0, 0.05)'
                                      : 'transparent',
                                    cursor: editing ? 'default' : 'pointer',
                                  }}
                                  onClick={handleRowClick(index)}
                                >
                                  <Table.Td {...provided.dragHandleProps}>
                                    <IconGripVertical size={18} />
                                  </Table.Td>
                                  <Table.Td>
                                    <EditableField
                                      editing={editing}
                                      value={question}
                                      onChange={handleFieldChange(
                                        index,
                                        'question'
                                      )}
                                    />
                                  </Table.Td>
                                  <Table.Td>
                                    <EditableField
                                      editing={editing}
                                      value={llmGradingInstructions}
                                      onChange={handleFieldChange(
                                        index,
                                        'llmGradingInstructions'
                                      )}
                                    />
                                  </Table.Td>
                                  <Table.Td>
                                    <EditableImportance
                                      editing={editing}
                                      value={importance}
                                      onChange={handleFieldChange(
                                        index,
                                        'importance'
                                      )}
                                    />
                                  </Table.Td>
                                  <Table.Td
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Group w='max-content' gap='xs'>
                                      <ActionIcon
                                        variant={editing ? 'filled' : 'subtle'}
                                        onClick={handleRowClick(index)}
                                      >
                                        {editing ? (
                                          <IconCheck size={16} />
                                        ) : (
                                          <IconPencil size={16} />
                                        )}
                                      </ActionIcon>
                                      <ActionIcon
                                        variant='subtle'
                                        color='red'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteRequirement(index)();
                                        }}
                                      >
                                        <IconTrash size={16} />
                                      </ActionIcon>
                                    </Group>
                                  </Table.Td>
                                </Table.Tr>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                      {provided.placeholder}
                    </Table.Tbody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
            <Flex justify='center'>
              <Button
                leftSection={<IconPlus size={20} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddRequirement();
                }}
              >
                Add Requirement
              </Button>
            </Flex>
            <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
              <Button variant='outline' onClick={handlePreviousStep}>
                Previous
              </Button>
              <Button onClick={handleNextStep}>Next</Button>
            </Flex>
          </>
        ),
      },
      {
        label: 'Follow-Up & Scheduling',
        isComplete: !!scriptInfo.values.callOutro,
        indent: true,
        element: (
          <div className='evenRows'>
            <Stack h='100%'>
              <Flex justify='space-between' align='center'>
                <Title order={5}>Follow-Up & Scheduling Settings</Title>
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: '10px',
                  }}
                ></div>
                <div style={{ paddingRight: '5px', paddingTop: '10px' }}>
                  <Switch
                    checked={scriptInfo.values.scheduleFollowUp}
                    onChange={handleScheduleFollowUpChange}
                    label='Schedule follow up'
                  />
                </div>
              </Flex>

              <Flex
                align='flex-center'
                style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <Text fz='sm' c='dimmed'>
                  This message will be used at the end of the call to debrief
                  the candidate and inform them of next steps. <br />
                  {scriptInfo.values.scheduleFollowUp
                    ? '\n\n\nIf the candidate scores above the passing score, they will be scheduled for a follow up call.'
                    : '  '}
                </Text>

                <Flex
                  style={{
                    opacity: scriptInfo.values.scheduleFollowUp ? 1.0 : 0.08,
                    pointerEvents: scriptInfo.values.scheduleFollowUp
                      ? 'all'
                      : 'none',
                    paddingLeft: '25px',
                    marginLeft: 'auto',
                  }}
                >
                  <div style={{ width: '130px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text size='sm' style={{ paddingBottom: '6px' }}>
                        Passing Score{' '}
                        <Tooltip label='Threshold score past which a follow up call will be scheduled'>
                          <IconHelp
                            size={16}
                            style={{
                              marginLeft: '4px',
                              position: 'relative',
                              top: '2px',
                              // color: '#868e96',
                            }}
                          />
                        </Tooltip>
                      </Text>
                    </div>

                    <Slider
                      size='sm'
                      color={
                        scriptInfo.values.scheduleFollowUp
                          ? '#228be6e8'
                          : 'gray'
                      }
                      {...scriptInfo.getInputProps('passingScore')}
                      // value={scriptInfo.values.passingScore}
                    />
                  </div>
                  <div
                    style={{
                      width: '60px',
                      paddingLeft: '14px',
                      paddingTop: '6px',
                    }}
                  >
                    <NumberInput
                      {...scriptInfo.getInputProps('passingScore')}
                      min={0}
                      max={100}
                      size='xs'
                      hideControls
                    />
                  </div>
                </Flex>
              </Flex>
              {/* {scriptInfo.values.scheduleFollowUp && (
                <Text fz='sm' c='dimmed'>
                  If the candidate's score is above the passing score, they will
                  be scheduled for a follow up call.
                </Text>
              )} */}
              <div
                style={{
                  whiteSpace: 'pre-line',
                  border: '1px solid #DCDCDC',
                  borderRadius: '4px',
                  fontSize: '14px',
                  padding: '8px',
                  paddingLeft: '14px',
                  cursor: 'not-allowed',
                  // minHeight: '100px', do not set this, causes issues
                  opacity: 0.94,
                  backgroundColor: '#F8F8F8',
                  pointerEvents: 'none',
                }}
              >
                {
                  getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
                    .selectionPageDisplay
                }
              </div>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </Stack>
          </div>
        ),
      },
      {
        label: 'Failed Call SMS',
        isComplete: !!scriptInfo.values.failedCallSms?.length,
        element: (
          <div className='evenRows'>
            <Stack h='100%'>
              <Title order={5}>SMS Message for Failed Calls</Title>
              <Text fz='sm' c='dimmed'>
                Add messages to be sent to the candidate if they don't pick up
                the phone. Use {'{candidate_name}'} to insert the candidate's
                name.
              </Text>

              <div
                style={{
                  padding: '20px',
                  border: '1px solid #8080802e',
                  borderRadius: '12px',
                }}
              >
                <Stack
                  mt='md'
                  style={{
                    maxHeight: '500px',
                    minHeight: '350px',
                    overflowY: 'auto',
                  }}
                >
                  {messages.length > 0 ? (
                    messages.map((message, index) => (
                      <Flex
                        key={index}
                        justify='space-between'
                        align='center'
                        className='chatBubble'
                        style={{
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          marginRight: '0px',
                          marginLeft: 'auto',
                          backgroundColor: '#f0f0f0',
                          borderRadius:
                            index === messages.length - 1
                              ? '20px 20px 0 20px'
                              : '20px',
                          width: msgEditIndex === index ? '70%' : 'auto',
                        }}
                      >
                        {msgEditIndex === index ? (
                          <Textarea
                            value={message}
                            onChange={(e) => {
                              const updatedMessages = [...messages];
                              updatedMessages[index] = e.target.value;
                              scriptInfo.setFieldValue(
                                'failedCallSms',
                                updatedMessages
                              );
                            }}
                            variant='unstyled'
                            style={{
                              flexGrow: 1,
                              fontSize: '0.875rem',
                              border: '1px solid #cac9c9',
                              borderRadius: '6px',
                            }}
                            minRows={1}
                            ref={msgTextareaRef}
                          />
                        ) : (
                          <Text
                            style={{ fontSize: '0.875rem' }}
                            onClick={() => handleEditMessage(index)}
                            className='editableText'
                          >
                            {message}
                          </Text>
                        )}
                        <Flex style={{ gap: '14px', marginLeft: '20px' }}>
                          {msgEditIndex === index ? (
                            <Button
                              onClick={() =>
                                handleSaveMessage(index, messages[index])
                              }
                            >
                              Done
                            </Button>
                          ) : (
                            <>
                              {/* <Button
                                variant='subtle'
                                onClick={() => handleEditMessage(index)}
                                style={{
                                  padding: '0px 4px',
                                }}
                              >
                                <IconPencil size={16} />
                              </Button> */}
                              <Button
                                variant='subtle'
                                onClick={() => handleRemoveMessage(index)}
                                style={{ padding: '0px 4px' }}
                              >
                                <IconTrash size={16} color={'red'} />
                              </Button>
                            </>
                          )}
                        </Flex>
                      </Flex>
                    ))
                  ) : (
                    <Text style={{ textAlign: 'center', color: 'gray' }}>
                      No messages added
                    </Text>
                  )}
                </Stack>
                <Flex align='center' gap='md' mt='md'>
                  <Input
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder='Type your message here'
                    style={{ flexGrow: 1 }}
                  />
                  <Button onClick={handleAddMessage}>Add Message</Button>
                </Flex>
              </div>

              {/* Navigation Buttons */}
              <Flex
                h='100%'
                justify='flex-end'
                align='flex-end'
                gap='md'
                className='paddingBottom'
              >
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </Stack>
          </div>
        ),
      },
      {
        label: 'Background Information',
        isComplete:
          !!scriptInfo.values.backgroundInfo && !areUnsavedCompanyInfoDiffs,
        element: (
          <div>
            {/* NOTE: outer minHeight currently prevents divs from overlapping in small browser window size. 
              must be greater than minHeight + total height of other elements */}
            <Stack style={{ minHeight: '300px', height: '40vh' }}>
              <Title order={5}>
                Job-Specific Background Info and Instructions
              </Title>
              <Text fz='sm' c='dimmed'>
                Background knowledge the AI can use to provide context or answer
                questions about the job.
                <br />
                This is not directly part of the script.
              </Text>
              <Textarea
                className='fullHeightTextarea'
                placeholder={`-This candidate had applied to our ad on Indeed.
-The jobsite is at 123 1st St, San Francisco, CA 94105. It is just off highway 1 near the McDonald's.
-This is a 6 month contract with opportunity to convert to perm after that.`}
                {...scriptInfo.getInputProps('backgroundInfo')}
              />
            </Stack>
            <Modal.Root
              opened={confirmEditCompanyModalOpen}
              onClose={() => {
                setConfirmEditCompanyModalOpen(false);
              }}
              size='lg'
            >
              <Modal.Overlay />
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title style={{ fontWeight: 'bold' }}>
                    Company Edit Warning Confirmation
                  </Modal.Title>
                  <Modal.CloseButton />
                </Modal.Header>
                <Divider />
                <Modal.Body>
                  <br />
                  <Alert
                    color='yellow'
                    icon={<IconExclamationCircle size={16} />}
                    style={{ padding: '16px' }}
                  >
                    <div style={{ fontSize: '16px' }}>
                      <b>Warning</b>: Changes to comapny info will affect all
                      scripts and campaigns
                    </div>
                  </Alert>
                  <br />
                  <div>
                    Modifications to the company background information will be
                    applied to <b>all jobs, scripts, and campaigns</b> in your
                    organization.
                  </div>
                  <br />
                  <br />
                  <Divider />
                  <div
                    style={{ display: 'flex', marginTop: '16px', gap: '20px' }}
                  >
                    <Button
                      variant='outline'
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        setConfirmEditCompanyModalOpen(false);
                        setEditingCompanyInfo(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='light'
                      style={{ marginRight: 'auto' }}
                      onClick={() => {
                        setEditingCompanyInfo(true);
                        setConfirmEditCompanyModalOpen(false);
                      }}
                    >
                      Acknowledge and Edit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>

            <Stack style={{ height: '40vh' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '14px', // a bit gross in terms of consistency accross panels but was feeling cluttered because of how loud this panel can be
                }}
              >
                <Title order={5}>
                  Company Info, Policies, and Instructions
                </Title>
                <ActionIcon
                  variant={editngCompanyInfo ? 'filled' : 'subtle'}
                  onClick={() => {
                    if (!editngCompanyInfo) {
                      setConfirmEditCompanyModalOpen(true);
                    } else {
                      setEditingCompanyInfo(false);
                      if (
                        orgBackgroundInfo.values.callOrgBackgroundInfo !==
                        companyInfoEdits
                      ) {
                        // Note: need this IF because otherwise the save is triggered
                        orgBackgroundInfo.setValues({
                          callOrgBackgroundInfo: companyInfoEdits,
                        });
                      }
                    }
                  }}
                  style={{
                    // marginLeft: 'auto',
                    marginLeft: editngCompanyInfo ? '14px' : '8px',
                  }}
                >
                  {editngCompanyInfo ? (
                    <IconCheck size={16} />
                  ) : (
                    <IconPencil size={16} />
                  )}
                </ActionIcon>
                {editngCompanyInfo && areUnsavedCompanyInfoDiffs && (
                  <div style={{ display: 'flex', marginLeft: 'auto' }}>
                    <Button
                      variant='transparent'
                      size='xs'
                      onClick={() => {
                        setEditingCompanyInfo(false);
                        setCompanyInfoEdits(
                          orgBackgroundInfo.values.callOrgBackgroundInfo
                        );
                      }}
                    >
                      Discard Edits
                    </Button>
                  </div>
                )}
              </div>

              {editngCompanyInfo && (
                <div className='callout'>
                  Modifications to the company background information will be
                  applied to all jobs, scripts, and campaigns in your
                  organization.
                </div>
              )}

              <Text fz='sm' c='dimmed'>
                Background knowledge the AI can use to provide context or answer
                questions about the company.
                <br />
                This is not directly part of the script.
              </Text>
              {editngCompanyInfo ? (
                <>
                  <Textarea
                    className='fullHeightTextarea'
                    placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
                    value={companyInfoEdits}
                    onChange={(e) => setCompanyInfoEdits(e.target.value)}
                  />
                </>
              ) : (
                <div
                  style={{
                    color: orgBackgroundInfo.values.callOrgBackgroundInfo
                      ? ''
                      : 'gray',
                    whiteSpace: 'pre-line',
                    border: '1px solid #DCDCDC',
                    borderRadius: '4px',
                    fontSize: '14px',
                    padding: '8px',
                    paddingLeft: '14px',
                    opacity: orgBackgroundInfo.values.callOrgBackgroundInfo
                      ? 0.95
                      : 0.6,
                    backgroundColor: orgBackgroundInfo.values
                      .callOrgBackgroundInfo
                      ? '#F8F8F8'
                      : '#FAFAFA',
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    height: '100%',
                  }}
                >
                  {orgBackgroundInfo.values.callOrgBackgroundInfo
                    ? orgBackgroundInfo.values.callOrgBackgroundInfo
                    : ORG_BACKGROUND_INFO_PLACEHOLDER}
                </div>
              )}
            </Stack>
            <Flex
              justify='flex-end'
              align='flex-end'
              gap='md'
              className='paddingBottom'
              style={{ marginTop: '16px' }}
            >
              <Button variant='outline' onClick={handlePreviousStep}>
                Previous
              </Button>
            </Flex>
          </div>
        ),
      },
    ];
  }, [
    scriptInfo,
    generatingScript,
    handleClickGenerateScript,
    requirements,
    handleDragEnd,
    activeStep,
    requirementsHandlers,
    requirementBeingEdited,
    handleDeleteRequirement,
    orgBackgroundInfo,
    areUnsavedCompanyInfoDiffs,
    editngCompanyInfo,
    confirmEditCompanyModalOpen,
    msgEditIndex,
    handleEditMessage,
    handleSaveMessage,
    companyInfoEdits,
    handleScheduleFollowUpChange,
    messages,
    newMessage,
    handleAddMessage,
    handleRemoveMessage,
  ]);

  function renderWithWaitLines(text) {
    const lines = text.split(/\n+/);
    return lines.map((line, index) => (
      <Fragment key={index}>
        <Text
          size='sm'
          style={{
            wordWrap: 'break-word', // Break long words
            wordBreak: 'break-all', // Break all lines
            whiteSpace: 'pre-wrap', // Allow text to wrap
          }}
        >
          {line}
        </Text>
        {
          <Text size='xs' c='gray' className='wait-for-response'>
            Wait for response
          </Text>
        }
      </Fragment>
    ));
  }

  useEffect(() => {
    setCanSubmitCampaign(steps.every((step) => step.isComplete));
  }, [steps]);

  const active = steps[activeStep];

  const handleStepClick = (step: number) => () => {
    setActiveStep(step);
  };

  const [editingName, setEditingName] = useState(false);
  const handleEditNameToggle = () => {
    if (editingName) {
      saveScript();
    }
    setEditingName(!editingName);
  };

  const nameEditRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (nameEditRef.current && editingName) {
      nameEditRef.current.focus();
      const value = nameEditRef.current.value;
      if (value) {
        nameEditRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [editingName]);

  if (!scriptFetched) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loader type='dots' style={{ marginTop: '2%' }} />
      </div>
    );
  }

  return (
    <div className='grid'>
      <Stack p='md' style={{ overflowY: 'scroll' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {editingName ? (
              <TextInput
                {...scriptInfo.getInputProps('name')}
                style={{ flex: 1 }}
                ref={nameEditRef}
              ></TextInput>
            ) : (
              <Title
                onClick={handleEditNameToggle}
                order={4}
                className='editableText'
              >
                {scriptInfo.values.name}{' '}
              </Title>
            )}

            <ActionIcon
              variant={editingName ? 'filled' : 'subtle'}
              onClick={handleEditNameToggle}
              style={{ marginLeft: '6px' }}
            >
              {editingName ? <IconCheck size={16} /> : <IconPencil size={16} />}
            </ActionIcon>
          </div>
          <div
            style={{
              paddingLeft: '6px',
              margin: '0px',
              fontSize: '10px',
              color: '#696969',
              marginTop: '6px',
            }}
          >
            {areUnsavedCompanyInfoDiffs ? (
              'Unsaved Company Info Changes'
            ) : !noChanges ? (
              <>
                {unsavedChanges || unsavedBackgroundInfoChanges
                  ? 'Saving...'
                  : 'Saved'}
              </>
            ) : (
              <div style={{ visibility: 'hidden' }}>{'Saving'}</div>
            )}
          </div>
        </div>

        {steps.map(({ label, description, isComplete, indent }, i) => (
          <>
            <NavLink
              key={i}
              active={activeStep === i}
              label={label}
              description={description}
              w={indent ? 'calc(100% - 26px)' : '100%'}
              onClick={handleStepClick(i)}
              color={isComplete ? '' : 'red'}
              style={{
                color: isComplete ? '' : 'var(--mantine-color-red-6)',
                borderRadius: '6px',
                marginLeft: indent ? '26px' : '0px',
              }}
            />
          </>
        ))}
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            // justifyContent: 'center',
            height: '100%',
            marginTop: '25px',
          }}
        >
          <Button
            variant='light'
            disabled={!canSubmitCampaign}
            leftSection={<IconPhone size={20} />}
            onClick={() => {
              if (verifyCallOutro()) {
                return;
              }
              setTestCallModalOpen(true);
            }}
            className={'campaign-action-button'}
          >
            Test Call
          </Button>
          <Modal
            opened={testCallModalOpen}
            onClose={() => {
              setTestCallModalOpen(false);
            }}
            title='Test Call'
          >
            <Card shadow='sm' miw={300}>
              <Stack>
                <TextInput
                  label='Candidate Name'
                  placeholder='John Doe'
                  required
                  {...testCallInfo.getInputProps('candidateName')}
                />
                <TextInput
                  label='Candidate Phone Number'
                  placeholder='123-456-7890'
                  required
                  {...testCallInfo.getInputProps('phoneNumber')}
                />
                <Select
                  label='Voice'
                  data={[
                    'Lisa (American)',
                    'George (American)',
                    'Abby (British)',
                    'William (British)',
                  ]}
                  placeholder='Select a voice'
                  nothingFoundMessage='No matching voices'
                  {...callerSettings.getInputProps('voiceName')}
                  allowDeselect={false}
                />
                {scriptInfo.values.scheduleFollowUp && (
                  <Select
                    label='Timezone'
                    value={candidateTimezone}
                    onChange={(value) => setCandidateTimezone(value || '')}
                    data={[
                      'America/New_York',
                      'America/Chicago',
                      'America/Denver',
                      'America/Los_Angeles',
                    ]}
                    placeholder='Select a timezone'
                    nothingFoundMessage='No matching timezones'
                    allowDeselect={false}
                  />
                )}
                <br />
                <Button
                  color='green'
                  leftSection={<IconPhone size={20} />}
                  onClick={handleTestCall}
                >
                  Test Call
                </Button>
              </Stack>
            </Card>
          </Modal>

          <Button
            onClick={() => {
              if (verifyCallOutro()) {
                return;
              }
              setCampaignModalOpen(true);
            }}
            disabled={!canSubmitCampaign}
            className={'campaign-action-button'}
            leftSection={<IconRocket size={20} />}
          >
            Campaign
          </Button>
          <CreateCampaignModal
            campaignModalOpen={campaginModalOpen}
            setCampaignModalOpen={setCampaignModalOpen}
            forScriptInfo={scriptInfo.values}
            forRequirements={requirements}
            callerSettingsProps={callerSettings}
          />
        </div>
      </Stack>
      <Paper p='md' style={{ overflowY: 'scroll' }}>
        <Stack h='100%'>{active.element && active.element}</Stack>
      </Paper>
      {testCallActive && <CallStatus />}
      <Modal
        opened={transcriptOpened}
        onClose={closeTranscript}
        size='80%'
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {' '}
        {callId && (
          <Transcript
            transcript={transcript}
            requirementGrades={requirementGrades}
            callId={callId}
          />
        )}
      </Modal>
    </div>
  );
}

const EditableField = ({ editing, value, onChange }) => {
  if (editing) {
    return (
      <Textarea
        autosize
        minRows={2}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        className='editableField'
      />
    );
  }
  return <div className='editableField'>{value}</div>;
};

const EditableImportance = ({ editing, value, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Box ref={containerRef} style={{ width: width }}>
      {editing ? (
        <Select
          value={value}
          data={IMPORTANCE_OPTIONS}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          allowDeselect={false}
          styles={{
            root: { width: '100%' },
            input: { width: '100%' },
          }}
        />
      ) : (
        <Badge color={IMPORTANCE_COLORS[value]}>
          {IMPORTANCE_LABELS[value]}
        </Badge>
      )}
    </Box>
  );
};
