import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Menu,
  Avatar,
  Divider,
  Text,
  Group,
  Modal,
  TextInput,
  Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useStytchB2BClient } from '@stytch/react/b2b';
import { Link } from 'react-router-dom';

import { clearLocalStorage } from './utils/userInfoUtils';
import axios from './api/axiosConfig';
import './NavigationBar.css';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [
    calendarModalOpened,
    { open: openCalendarModal, close: closeCalendarModal },
  ] = useDisclosure(false);
  const firstName = localStorage.getItem('firstName');
  const location = useLocation();
  const stytch = useStytchB2BClient();
  const logout = useCallback(async () => {
    await stytch.session.revoke();
    clearLocalStorage();
  }, [stytch]);
  const [calAPIKey, setCalAPIKey] = useState(
    localStorage.getItem('calAPIKey') || ''
  );
  const [calEventTypeID, setCalEventTypeID] = useState(
    localStorage.getItem('calEventTypeID') || ''
  );
  const [savingCalSettings, setSavingCalSettings] = useState(false);
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');

  const links = [
    { path: '/', label: 'Scripts' },
    { path: '/campaigns', label: 'Campaigns' },
  ];

  if (isInternal) {
    links.push({ path: '/sourcing', label: 'Sourcing' });
    links.push({ path: '/resume-editor', label: 'Resume Editor' });
    links.push({ path: '/job-posting-tips', label: 'Job Posting Tips' });
  }

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleSaveCal = async () => {
    setSavingCalSettings(true);
    const payload = {
      calApiKey: calAPIKey,
      calEventTypeID: calEventTypeID,
    };

    try {
      const response = await axios.post('/cal_integration', payload);
      console.log(response.data); // Log the response data for debugging purposes

      // Update localStorage
      localStorage.setItem('calAPIKey', calAPIKey);
      localStorage.setItem('calEventTypeID', calEventTypeID);
      closeCalendarModal();
    } catch (error) {
      alert(
        'Failed to save calendar settings. Please check your API Key and Event Type ID. If the issue persists, contact support@salv.ai'
      );
    }
    setSavingCalSettings(false);
    // TODO: add a save successful message
  };

  // Code for determining which navigation item to highlight
  // Function to determine the length of the match between the current path and the link path
  const getMatchLength = (linkPath) => {
    if (location.pathname === linkPath) {
      return linkPath.length; // Exact match
    }
    if (
      location.pathname.startsWith(linkPath + '/') ||
      (linkPath === '/' && location.pathname !== '/')
    ) {
      return linkPath.length; // Match considering root and subdirectories
    }
    return 0; // No match
  };

  // Find the index of the link with the longest match
  let maxMatchIndex = -1;
  let maxMatchLength = 0;
  links.forEach((link, index) => {
    const matchLength = getMatchLength(link.path);
    if (matchLength > maxMatchLength) {
      maxMatchLength = matchLength;
      maxMatchIndex = index;
    }
  });

  // Render navigation items, highlighting the one with the longest match
  const navigationItems = links.map((link, index) => (
    <Text
      key={link.label}
      className={`text-link ${index === maxMatchIndex ? 'bold' : ''}`}
      onClick={() => navigate(link.path)}
      data-text={link.label}
    >
      {link.label}
    </Text>
  ));

  return (
    <div className='header'>
      <div className='nav-header'>{navigationItems}</div>
      <div className='menu-header'>
        <Menu width={200} position='bottom-end'>
          <Menu.Target>
            <Group gap='xs' className='user-menu'>
              <Text style={{ color: 'black' }}>{firstName}</Text>
              <Avatar className='user-avatar' />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Account</Menu.Label>
            {/* <Menu.Item>Profile</Menu.Item> */}
            <Menu.Item onClick={openCalendarModal}>
              Calendar Integration
            </Menu.Item>
            {/* <Menu.Item>Profile</Menu.Item> */}

            <Link to='/user-settings' style={{ textDecoration: 'none' }}>
              <Menu.Item>User Management</Menu.Item>
            </Link>

            <Divider />

            <Menu.Item color='red' onClick={handleLogout}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
      <Modal
        title='Calendar Integration'
        centered
        opened={calendarModalOpened}
        onClose={() => {
          closeCalendarModal();
          setCalAPIKey(localStorage.getItem('calAPIKey'));
          setCalEventTypeID(localStorage.getItem('calEventTypeID'));
        }}
      >
        <TextInput
          label='Cal.com API Key'
          placeholder='cal_live_b16653451d4c0568832e74e03d'
          value={calAPIKey}
          onChange={(e) => setCalAPIKey(e.target.value)}
          required
        />
        <TextInput
          label='Cal.com Event Type ID'
          mt='md'
          placeholder='567346'
          value={calEventTypeID}
          onChange={(e) => setCalEventTypeID(e.target.value)}
          required
        />
        <Group justify='center' mt='xl'>
          {savingCalSettings ? (
            <Button disabled>Saving...</Button>
          ) : (
            <Button onClick={handleSaveCal}>Save and Close</Button>
          )}
        </Group>
      </Modal>
    </div>
  );
};

export default NavigationBar;
