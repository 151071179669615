import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Title, Button, Modal, Center, Loader } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';
import env from 'env';
import { notifications } from '@mantine/notifications';
import SortableHeader from 'components/common/SortableHeader';
import { RequirementImportance } from 'pages/editorv2/EditorPageV2';

import axios from '../../../api/axiosConfig';
import Transcript from '../../../components/transcript/Transcript';
import EditCampaignModal from './EditCampaignModal';
import './CampaignDetailsPage.css';
import {
  formatToLocalTime,
  convertSecondsToMinutesAndSeconds,
} from '../../../utils/dateUtils';
const CallStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  VOICEMAIL: 'VOICEMAIL',
  NO_ANSWER: 'NO_ANSWER',
  INVALID_NUMBER: 'INVALID_NUMBER',
};

const CallInitiatedBy = {
  CAMPAIGN: 'CAMPAIGN',
  AUTOMATIC_RETRY: 'AUTOMATIC_RETRY',
  CANDIDATE_RESCHEDULED: 'CANDIDATE_RESCHEDULED',
  INBOUND: 'INBOUND',
};

const SortColumn = {
  LAST_UPDATED: 'last_updated',
  OVERALL_GRADE: 'overall_grade',
  COMPLETION_RATE: 'completion_rate',
  CALL_LENGTH_SEC: 'call_length_sec',
};

function convertUpperSnakeToTitle(str) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back together with a space
}

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const CampaignDetailsPage = () => {
  const [paginationObject, setPaginationObject] = useState({
    skip: 0,
    limit: 50,
  });
  const [sort, setSort] = useState<SortConfig | undefined>({
    column: SortColumn.COMPLETION_RATE,
    direction: 'descending',
  });
  const handleSort = (column: string) => () => {
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingCalls, setIsFetchingCalls] = useState(true);
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);
  const [isFetchingTranscript, setIsFetchingTranscript] = useState(false);
  const [candidates, setCandidates] = useState<any>([]);
  const [campaignJobs, setCampaignJobs] = useState([]);
  const [campaignName, setCampaignName] = useState(' ');
  const [isEmailSending, setIsEmailSending] = useState(false);
  // const [isRetryingPendingCalls, setIsRetryingPendingCalls] = useState(false);
  const [isCancellingCampaign, setIsCancellingCampaign] = useState(false);
  const [isUpdatingCampaign, setIsUpdatingCampaign] = useState(false);
  // const [expandedRows, setExpandedRows] = useState([]);
  const [transcriptError, setTranscriptError] = useState(false);
  const [transcriptData, setTranscriptData] = useState({
    transcript: '',
    requirementGrades: {} as Record<
      string,
      { answer: string; grade: string; importance: RequirementImportance }
    >,
    smsTranscript: '',
    callId: '',
    overallGrade: 0,
    questionCompletionRate: 0,
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  const location = useLocation();
  const match = location.pathname.match(/\/campaigns\/campaign\/([^/]+)/);
  const campaignId = match ? match[1] : null;

  const fetchCandidates = useCallback(
    async ({ skip, limit }) => {
      if (!campaignId) return;
      try {
        setIsFetchingCalls(true);
        const payload = {
          sort: {
            column: sort?.column || SortColumn.LAST_UPDATED,
            descending: sort?.direction === 'descending' || !sort?.direction,
          },
        };
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/candidates_and_calls`,
          payload,
          {
            params: {
              skip,
              limit,
            },
          }
        );
        setCandidates((prev) => {
          console.log('Previous candidates:', prev); // Log previous candidates
          return [...prev, ...response.data.candidates]; // Append new candidates
        });
        setTotalCount(Number(response.data['total_records']));

        // setCampaignJobs(response.data.jobs);
        // setCampaignName(response.data.campaign_name + ' Results');
      } catch (error) {
        console.error('Error fetching campaign calls:', error);
        notifications.show({
          title: 'There was an error retrieving the campaign calls',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsFetchingCalls(false);
      }
    },
    [setTotalCount, setCandidates, campaignId, sort]
  );

  const fetchCampaignDetails = useCallback(async () => {
    if (!campaignId) return;

    try {
      setIsFetchingDetails(true);
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}`
      );
      // setCampaignJobs(response.data.jobs);
      setCampaignName(response.data.campaign.campaign_name);
    } catch (error) {
      console.error('Error fetching campaign details:', error);
      notifications.show({
        title: 'There was an error retrieving the campaign details',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsFetchingDetails(false);
    }
  }, [setCampaignName, campaignId]);

  useEffect(() => {
    if (!campaignId) return;
    setCandidates([]);
    setTotalCount(0);
    // Reset pagination when campaignId or sort changes
    setPaginationObject((prev) => ({ ...prev, skip: 0 }));
  }, [campaignId, sort]);

  useEffect(() => {
    setIsFetchingCalls(true);
    const debouncedFetchCandidates = debounce(() => {
      if (paginationObject.skip === 0) {
        setCandidates([]);
        setTotalCount(0);
      }
      fetchCandidates({
        limit: paginationObject.limit,
        skip: paginationObject.skip,
      });
    }, 300); // Debounce delay (e.g., 300ms)

    if (!campaignId) return;
    debouncedFetchCandidates();

    // Cleanup debounced functions when the component unmounts or dependencies change
    return () => {
      debouncedFetchCandidates.cancel();
    };
  }, [campaignId, paginationObject, fetchCandidates]);

  useEffect(() => {
    setIsFetchingDetails(true);
    const debouncedFetchCampaignDetails = debounce(() => {
      fetchCampaignDetails();
    }, 300);
    if (!campaignId) return;
    debouncedFetchCampaignDetails();
    // Cleanup debounced functions when the component unmounts or dependencies change
    return () => {
      debouncedFetchCampaignDetails.cancel();
    };
  }, [campaignId, fetchCampaignDetails]);

  const updateCampaignJobs = async (jobs) => {
    setIsUpdatingCampaign(true);
    const payload = {
      jobs: jobs,
      campaignId: campaignId,
    };
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/update_campaign_jobs`,
        payload
      );
      setCampaignJobs(response.data.jobs);
    } catch (error) {
      console.error('Error updating campaign jobs:', error);
    } finally {
      setEditModalOpen(false);
      setIsUpdatingCampaign(false);
    }
  };

  const fetchTranscriptData = async (candidateId) => {
    setIsFetchingTranscript(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/call_details/`
      );
      setTranscriptData({
        transcript: response.data.transcript,
        requirementGrades: response.data.requirement_grades,
        smsTranscript: response.data.sms_transcript,
        overallGrade: response.data.overall_grade,
        questionCompletionRate: response.data.question_completion_rate,
        callId: response.data.call_id,
      });

      setTranscriptError(false);
    } catch (error) {
      setTranscriptError(true);
      console.error('Error fetching transcript data:', error);
      notifications.show({
        title: 'There was an error retrieving the call details',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsFetchingTranscript(false);
    }
  };

  const handleRowClick = (candidateId) => {
    openTranscript();
    fetchTranscriptData(candidateId);
  };

  const sendResultsEmail = async () => {
    console.log('Sending results email...');
    setIsEmailSending(true);
    const payload = { campaign_id: campaignId };
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/trigger_results_email`,
        payload
      );
      showNotification({
        title: 'Success',
        message: 'Results email sent successfully!',
        color: 'green',
        autoClose: 10000,
      });
    } catch (error) {
      console.error('Error sending results email:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to send results email. Please try again later.',
        color: 'red',
        autoClose: 10000,
      });
    } finally {
      setIsEmailSending(false);
    }
  };

  // const retryPendingCalls = async () => {
  //   console.log('Retrying pending calls..');
  //   setIsRetryingPendingCalls(true);
  //   const payload = { campaign_id: campaignId };
  //   try {
  //     await axios.post(
  //       `${env.REACT_APP_SERVER_URL}/retry_pending_calls`,
  //       payload
  //     );
  //     showNotification({
  //       title: 'Success',
  //       message: 'Pending Calls Re-queued. Please wait for them to complete.',
  //       color: 'green',
  //       autoClose: 10000,
  //     });
  //   } catch (error) {
  //     console.error('Error re-queuing calls:', error);
  //     showNotification({
  //       title: 'Error',
  //       message: 'Failed to queue calls. Please try again later.',
  //       color: 'red',
  //       autoClose: 10000,
  //     });
  //   } finally {
  //     setIsRetryingPendingCalls(false);
  //   }
  // };

  const cancelCampaign = async () => {
    console.log('Cancelling campaign..');
    setIsCancellingCampaign(true);
    const payload = { campaign_id: campaignId };
    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/cancel_campaign`, payload);
      showNotification({
        title: 'Success',
        message: 'Calls cancelled. Refresh to see updated status.',
        color: 'green',
        autoClose: 10000,
      });
    } catch (error) {
      console.error('Error cancelling campaign:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to cancel calls. Please try again later.',
        color: 'red',
        autoClose: 10000,
      });
    } finally {
      setIsCancellingCampaign(false);
    }
  };

  // const toggleRow = (fullName) => {
  //   setExpandedRows((prevExpandedRows) =>
  //     prevExpandedRows.includes(fullName)
  //       ? prevExpandedRows.filter((name) => name !== fullName)
  //       : [...prevExpandedRows, fullName]
  //   );
  // };

  const candidateToCallStatus = (candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      if (lastCall.call_status === CallStatus.PENDING) {
        if (calls.call_initiated_by === CallInitiatedBy.AUTOMATIC_RETRY) {
          return 'Automatic Callback Pending';
        }
        if (calls.call_initiated_by === CallInitiatedBy.CANDIDATE_RESCHEDULED) {
          return 'Callback Pending';
        }
        return 'Pending';
      }
      return convertUpperSnakeToTitle(lastCall.call_status);
    }
  };

  const candidateToSmsStatus = (candidate) => {
    return convertUpperSnakeToTitle(candidate.sms_status);
  };

  const getLongestCall = (candidate) =>
    candidate.calls?.reduce(
      (longest, current) =>
        current.call_length_sec > longest.call_length_sec ? current : longest,
      candidate.calls[0]
    );

  const isCallCompleted = (call) => call?.call_status === CallStatus.COMPLETED;
  const hasCallHappened = (call) =>
    call?.call_status !== CallStatus.PENDING &&
    call?.call_status !== CallStatus.CANCELLED;

  const candidateToRecentCallLength = (candidate) => {
    const firstCall = getLongestCall(candidate);
    return isCallCompleted(firstCall)
      ? convertSecondsToMinutesAndSeconds(firstCall.call_length_sec)
      : '-';
  };

  const candidateToCompletionRate = (candidate) => {
    const firstCall = getLongestCall(candidate);
    return isCallCompleted(firstCall)
      ? `${(firstCall.question_completion_rate * 100).toFixed(0)}%`
      : '-';
  };

  const candidateToOverallGrade = (candidate) => {
    const firstCall = getLongestCall(candidate);
    return isCallCompleted(firstCall)
      ? `${firstCall.overall_grade.toFixed(0)}%`
      : '-';
  };

  const candidateToLastCalled = (candidate) => {
    const lastCall = candidate.calls.find(
      (c) => c.call_status !== CallStatus.PENDING
    );
    if (!lastCall) return '-';
    else return formatToLocalTime(candidate.calls[0].last_updated);
  };

  const candidateToNumberOfCalls = (candidate) => {
    return candidate.calls.filter((c) => hasCallHappened(c)).length || 0;
  };

  return (
    <div className='campaign-details-page' style={{ marginTop: '15pt' }}>
      <div style={{ display: 'flex', marginBottom: '15pt' }}>
        {isFetchingDetails ? (
          <Center p='lg'>
            <></>
          </Center>
        ) : (
          <>
            <Title order={2} style={{ marginRight: 'auto' }}>
              {campaignName || 'Campaign Details'}
            </Title>

            <Button
              variant='light'
              style={{ marginLeft: '20px' }}
              onClick={sendResultsEmail}
              disabled={isEmailSending}
            >
              {isEmailSending ? 'Sending...' : 'Email Results CSV'}
            </Button>
            {/* <Button
              variant='light'
              style={{ marginLeft: '20px' }}
              onClick={retryPendingCalls}
              disabled={isRetryingPendingCalls}
            >
              {isRetryingPendingCalls
                ? 'Re-queing Calls...'
                : 'Retry Pending Calls'}
            </Button> */}
            {isInternal && (
              <>
                <Button
                  variant='light'
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                >
                  {'Associate Indeed Jobs'}
                </Button>
                <EditCampaignModal
                  modalOpen={editModalOpen}
                  setModalOpen={setEditModalOpen}
                  initialJobs={campaignJobs}
                  updateCampaignJobs={updateCampaignJobs}
                  saving={isUpdatingCampaign}
                />
              </>
            )}
            <Button
              variant='light'
              color='red'
              style={{ marginLeft: '20px' }}
              onClick={cancelCampaign}
              disabled={isCancellingCampaign}
            >
              {isCancellingCampaign ? 'Cancelling...' : 'Cancel Pending Calls'}
            </Button>
          </>
        )}
      </div>
      {/* <div>Length {candidates?.length}</div> */}
      <div
        style={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'scroll',
          background: 'white',
        }}
        onScroll={(e) => {
          const element = e.target as HTMLElement;

          if (
            element.scrollHeight - element.scrollTop <=
              element.clientHeight + 20 &&
            !isFetchingCalls
          ) {
            if (paginationObject.skip + paginationObject.limit >= totalCount)
              return;

            setIsFetchingCalls(true);
            setPaginationObject({
              limit: paginationObject.limit,
              skip: paginationObject.skip + paginationObject.limit,
            });
          }
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Number</Table.Th>
              <Table.Th>Last Called</Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.CALL_LENGTH_SEC}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.CALL_LENGTH_SEC)}
              >
                Call Length
              </SortableHeader>
              <Table.Th>Attempted Calls</Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.OVERALL_GRADE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.OVERALL_GRADE)}
              >
                Score
              </SortableHeader>
              <SortableHeader
                sorted={sort?.column === SortColumn.COMPLETION_RATE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.COMPLETION_RATE)}
              >
                Completion Rate
              </SortableHeader>
              <Table.Th>SMS Status</Table.Th>
              <Table.Th>Call Status</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {candidates.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.Tr
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(item.candidate_id);
                    }}
                    // onClick={() => toggleRow(item.full_name)}
                  >
                    <Table.Td>{item.full_name}</Table.Td>
                    <Table.Td>{item.phone_number}</Table.Td>
                    <Table.Td>{candidateToLastCalled(item)}</Table.Td>
                    <Table.Td>{candidateToRecentCallLength(item)}</Table.Td>
                    <Table.Td>{candidateToNumberOfCalls(item)}</Table.Td>
                    <Table.Td>{candidateToOverallGrade(item)}</Table.Td>
                    <Table.Td>{candidateToCompletionRate(item)}</Table.Td>
                    <Table.Td>{candidateToSmsStatus(item)}</Table.Td>
                    <Table.Td>{candidateToCallStatus(item)}</Table.Td>
                  </Table.Tr>
                  {/* Expandable Rows
                  {Array.isArray(expandedRows) &&
                    expandedRows.includes(item.full_name) &&
                    item.calls.map((callDetail, callIndex) => (
                      <Table.Tr
                        key={`${item.full_name}-${callIndex}`}
                        style={{ backgroundColor: '#f0f4ff' }}
                      >
                        <Table.Td>{item.full_name}</Table.Td>
                        <Table.Td
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(callDetail.call_id);
                          }}
                          style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {item.phone_number}
                        </Table.Td>
                        <Table.Td>
                          {formatToLocalTime(callDetail.last_updated)}
                        </Table.Td>
                        <Table.Td>
                          {callDetail.call_status === 'COMPLETED'
                            ? convertSecondsToMinutesAndSeconds(
                                callDetail.call_length_sec
                              )
                            : ''}
                        </Table.Td>
                        <Table.Td>{callDetail.overall_grade}</Table.Td>
                        <Table.Td>
                          {(callDetail.question_completion_rate * 100).toFixed(
                            0
                          )}
                          %
                        </Table.Td>
                        <Table.Td>{callDetail.sms_status}</Table.Td>
                        <Table.Td>{callDetail.call_status}</Table.Td>
                      </Table.Tr>
                    ))} */}
                </React.Fragment>
              );
            })}
            {!isFetchingCalls && candidates && candidates?.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <Center p='lg'>No data available</Center>
                </td>
              </tr>
            )}
            {isFetchingCalls && (
              <tr>
                <td colSpan={8}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>

      <Modal
        opened={transcriptOpened}
        onClose={closeTranscript}
        size='100%'
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {isFetchingTranscript ? (
          <Center p='lg'>
            <Loader size='sm' type='dots' />
          </Center>
        ) : transcriptError ? (
          <div
            style={{
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {/* <Image
              radius='md'
              h={200}
              w='auto'
              src='https://static.vecteezy.com/system/resources/thumbnails/023/033/009/small_2x/rocket-spaceship-fail-landing-at-space-planet-for-something-went-wrong-error-message-empty-state-illustration-vector.jpg'
            /> */}
            <Title c={'black'}>Something went wrong..!</Title>
          </div>
        ) : (
          <Transcript
            transcript={transcriptData.transcript}
            requirementGrades={transcriptData.requirementGrades}
            callId={transcriptData.callId}
            smsTranscript={transcriptData.smsTranscript}
          />
        )}
      </Modal>
    </div>
  );
};

export default CampaignDetailsPage;
