import { AdminPortalMemberManagement } from '@stytch/react/b2b/adminPortal';
import React, { useEffect } from 'react';
const styles = { fontFamily: 'Arial' };

const roleToName = (role) => {
  if (role.role_id === 'stytch_admin') {
    return 'Org Admin';
  } else if (role.role_id === 'stytch_member') {
    return 'Org Member';
  }
  return role.role_id;
};

const roleToDescription = (role) => {
  if (role.role_id === 'stytch_admin') {
    return 'All permissions including user management';
  } else if (role.role_id === 'stytch_member') {
    return 'Default application permissions';
  }
  return '';
};

const config = {
  getRoleDisplayName: roleToName,
  getRoleDescription: roleToDescription,
};

const UserSettingsPage = () => {
  useEffect(() => {
    // Hide unsupported UI elements
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          document.querySelectorAll('div').forEach((div) => {
            const h4Arr = div.querySelectorAll(':scope > h4'); // only select direct children
            if (!h4Arr?.length) {
              return;
            }
            const h4 = h4Arr[0];
            if (h4 && h4.textContent.includes('Enable MFA')) {
              div.parentElement.style.display = 'none';
            } else if (
              h4 &&
              h4.textContent.includes('Exempt from primary auth requirements')
            ) {
              div.parentElement.style.display = 'none';
            }
          });
        }
      });
    });

    const targetNode = document.querySelector('.watch'); // Update selector to match the actual container
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    // Cleanup observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div style={{ overflowY: 'scroll', margin: '20px' }} className='watch'>
      <AdminPortalMemberManagement styles={styles} config={config} />
    </div>
  );
};

export default UserSettingsPage;
